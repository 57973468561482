import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {Container, Table} from "reactstrap";
import {FormattedNumber} from "react-intl";
import {initializeHome} from "../actions";
import NavigationContainer from "./NavigationContainer";
import './Home.css'
import {Area, ComposedChart, Line, YAxis} from "recharts";
import {withRouter} from "react-router";
import DataTableMetricCell from "../components/DataTableMetricCell";

class Home extends Component {
  componentDidMount() {
    this.props.onInit(this.props.match.params.clientId);
  }

  onRowClick = (client_id) => {
    this.props.history.push(`/${client_id}`);
  };

  render() {
    const {clients, stats} = this.props;

    //TODO: This is a bit ugly. Maybe clients should be an array rather than a dict
    const client_objects = Object.values(clients);
    client_objects.sort((a, b) => { return a.name > b.name });
    const client_ids = client_objects.map(x => x._id);

    return (
        <div>
          <NavigationContainer showDropdown={false} />
          <div id='home'>
              <Table id='clients'>
                <thead>
                <tr>
                  <th className='hidden'></th>
                  <th className='hidden'></th>
                  <th colSpan='2' className='d-none d-sm-table-cell'>Yesterday</th>
                  <th colSpan='3' className='border-end d-none d-lg-table-cell'>This Month</th>
                  <th className='hidden d-none d-xl-table-cell'>Performance</th>
                </tr>
                </thead>
                <tbody>
                {client_ids.map((client_id, i) => {
                  const client = clients[client_id];

                  return (
                    <tr key={i} onClick={() => this.onRowClick(client_id)}>
                      <td className='logo'>
                        <div>
                          <img alt={client.name} src={`https://nara-analytics.s3.amazonaws.com/clients/${client_id}.png`}/>
                        </div>
                      </td>
                      <td>
                        <p>{client.name}</p>
                      </td>
                      <td className='text-right border-start d-none d-sm-table-cell'>
                        { stats[client_id] ?
                        <div>
                          <p><DataTableMetricCell column={(stats[client_id].kpi || {})} value={(stats[client_id].kpi || {}).yesterday} /></p>
                          <small>{(stats[client_id].kpi || {}).display_name}</small>
                        </div>
                          :
                          <img src={'/spinner.gif'} alt='Spinner' style={{width: 24}} />
                        }
                      </td>
                      <td className='text-right border-end d-none d-sm-table-cell'>
                        {stats[client_id] ?
                          <Fragment>
                            <p>
                              <FormattedNumber style='currency' currency={client['currency']} value={(stats[client_id] || {}).spend_yesterday || 0} minimumFractionDigits={0} maximumFractionDigits={0}/>
                            </p>
                            <small>Spend</small>
                          </Fragment>
                          :
                          <img src={'/spinner.gif'} alt='Spinner' style={{width: 24}}/>
                        }
                      </td>
                      <td className='text-right border-start  d-none d-lg-table-cell'>
                        { stats[client_id] ?
                          <div>
                            <p><DataTableMetricCell column={(stats[client_id].kpi || {})} value={(stats[client_id].kpi || {}).mtd} /></p>
                            <small>{(stats[client_id].kpi || {}).display_name}</small>
                          </div>
                          :
                          <img src={'/spinner.gif'} alt='Spinner' style={{width: 24}} />
                        }
                      </td>
                      <td className='text-right  d-none d-lg-table-cell'>
                        {stats[client_id] ?
                          <Fragment>
                            <p><FormattedNumber style='currency' currency={client['currency']} value={(stats[client_id] || {}).spend_mtd || 0} minimumFractionDigits={0} maximumFractionDigits={0}/></p>
                            <small>Spend</small>
                          </Fragment>
                          :
                          <img src={'/spinner.gif'} alt='Spinner' style={{width: 24}}/>
                        }
                      </td>
                      <td className='text-right border-end  d-none d-lg-table-cell'>
                        {stats[client_id] ?
                          <Fragment>
                            <p><FormattedNumber style='currency' currency={client['currency']} value={(stats[client_id] || {}).projected_spend || 0} minimumFractionDigits={0} maximumFractionDigits={0}/></p>
                            <small>Projected Spend</small>
                          </Fragment>
                          :
                          <img src={'/spinner.gif'} alt='Spinner' style={{width: 24}}/>
                        }
                      </td>
                      <td className='chart d-none d-xl-table-cell'>
                        <ComposedChart width={250} height={60} data={(stats[client_id] || {}).trend} baseValue='dataMin'>
                          <defs>
                            <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="0%" stopColor="#EFF4FC" stopOpacity={1}/>
                              <stop offset="100%" stopColor="#EFF4FC" stopOpacity={0.2}/>
                            </linearGradient>
                          </defs>
                          <YAxis yAxisId='left' type="number" domain={[0, 'dataMax']} hide={true }/>
                          <YAxis yAxisId='right' type="number" domain={['dataMin', 'dataMax']} hide={true} />
                          <Area type='monotone' yAxisId='left' dataKey='spend' stroke={null} fillOpacity={1} fill='url(#gradient)' isAnimationActive={false} />
                          <Line type='monotone' dataKey='performance' yAxisId='right' stroke='#0fab4c' isAnimationActive={false} dot={false} />
                        </ComposedChart>
                      </td>
                    </tr>
                  )
                })}
                </tbody>
              </Table>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.clients.clients || {},
  stats: state.clients.stats || {}
});

const mapDispatchToProps = dispatch => ({
  onInit: (clientId) => dispatch(initializeHome(clientId))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))
