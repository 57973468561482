import React, {Component} from 'react';
import FontAwesome from 'react-fontawesome';
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import CreativeCarousel from "./CreativeCarousel";

class Thumbnail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      hovering: false
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      hovering: false
    });
  };

  render() {
    const {url} = this.props;
    const thumbnailUrl = url ? (url).replace(/(.*.com)\/(.*)\..*$/, '$1/thumbnails/$2.jpg') : null;
    const isVideo = url && url.indexOf('.mp4') >= 0;

    return (
      <div style={{position: 'relative', display: 'inline-block'}} onMouseEnter={() => this.setState({hovering: true})} onMouseLeave={() => this.setState({hovering: false})} onClick={this.toggle}>
        {!url &&
          <img src={'/unavailable.png'} alt='Media unavailable' style={{borderRadius: 5, marginTop: '-0.7rem', marginBottom: '-0.7rem'}} />
        }
        {url && this.state.hovering && isVideo &&
          <video src={url} placeholder={thumbnailUrl} autoPlay={true} playsInline={true} muted={true} controls={false} height={100} style={{borderRadius: 5, marginTop: '-0.7rem', marginBottom: '-0.7rem', position: 'absolute', top: 0, left: 0}}/>
        }
        {url &&
        <img src={thumbnailUrl} style={{borderRadius: 5, marginTop: '-0.7rem', marginBottom: '-0.7rem'}}/>
        }
        {url && isVideo ?
          <FontAwesome name='video' style={{fontSize: 16, position: 'absolute', 'bottom': -9, 'right': 4, color: '#ffffff', borderBottom: 'none'}}/>
          :
          <FontAwesome name='image' style={{fontSize: 18, position: 'absolute', 'bottom': -9, 'right': 4, color: '#ffffff', borderBottom: 'none'}}/>
        }
        <Modal size='md' centered={true} isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          {/*<ModalHeader toggle={this.toggle}>Query builder</ModalHeader>*/}
          <ModalBody>
            <CreativeCarousel url={url} />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default Thumbnail;
