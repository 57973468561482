import React, {Component, Fragment} from 'react';
import {Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Form from "@rjsf/core";
import './JSONSchemaDialog.css'

export default class JSONSchemaDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      errorOccurred: false,
      formData: {}
    }
  }

  show = () => {
    this.setState({
      isOpen: true
    })
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    const {schema, uiSchema, onSave, title, size} = this.props;

    const onClick = () => {
      // https://github.com/rjsf-team/react-jsonschema-form/issues/2104#issuecomment-847924986

      this.form.formElement.dispatchEvent(
        new CustomEvent('submit', {
          cancelable: true,
          bubbles: true
        }),
      );
    }

    const onSubmit = (e) => {
      this.setState({
        errorOccurred: false
      })

      onSave(e.formData).then(() => {
        this.toggle();
      }).catch((error) => {
        this.setState({
          errorOccurred: true
        })
      })
    };

    return (
      <Modal id='json-schema-dialog' size={size || 'md'} centered={true} isOpen={this.state.isOpen} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
        <ModalBody>
          {this.state.errorOccurred &&
            <Alert color="danger">
              An error occurred. Please try again.
            </Alert>
          }
          <Form ref={(form) => {
            this.form = form;
          }}
                formData={this.state.formData}
                onChange={({formData}) => this.setState({formData})}
                schema={schema}
                uiSchema={uiSchema}
                onSubmit={onSubmit}
          >
            <Fragment/>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          <Button color="primary" onClick={onClick}>Save</Button>{' '}
        </ModalFooter>
      </Modal>
    )
  }
}
