import React, {Component} from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Container} from "reactstrap";
import NavigationContainer from "./NavigationContainer";
import './Settings.css'
import {initializeSettings} from "../actions";
import TaggingSettings from "./TaggingSettings";
import CustomDimensionSettings from "./CustomDimensionSettings";

class Settings extends Component {
  componentDidMount() {
    this.props.onInit(this.props.match.params.clientId);
  }

  render() {
    return (
      <div id='settings'>
        <NavigationContainer showDropdown={true}/>
        <Container fluid={true}>
          <TaggingSettings />
          <hr />
          <CustomDimensionSettings />
          <hr />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = dispatch => ({
  onInit: (clientId) => dispatch(initializeSettings(clientId)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Settings))
