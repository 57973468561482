import React, {Component} from 'react';
import PaginationControls from "../components/PaginationControls";
import {selectPage, updatePageSize} from "../actions";
import {connect} from "react-redux";

class ReportPagination extends Component {
  render() {
    const {rowCount, query, pageSize, onPageSelect, onPageSizeChange} = this.props;

    return (
      <PaginationControls
        rowCount={rowCount}
        pageSize={pageSize}
        pageIndex={query.page_index}
        onPageSelect={onPageSelect}
        onPageSizeChange={onPageSizeChange}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  rowCount: state.report.row_count,
  query: state.report.query,
  pageSize: state.report.query.page_size
});

const mapDispatchToProps = dispatch => ({
  onPageSelect: (index) => dispatch(selectPage(index)),
  onPageSizeChange: (selectedOption) => dispatch(updatePageSize(selectedOption.value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportPagination);
