import React, {Component} from 'react';
import {Button} from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import {connect} from "react-redux";
import {createTag, createUser} from "../actions";
import JSONSchemaDialog from "./JSONSchemaDialog";

class NewUserButton extends Component {
  constructor(props) {
    super(props);

    this.dialogRef = React.createRef();
  }

  render() {
    const {clients, onSave} = this.props;

    const schema = {
      "type": "object",
      "required": [
        "email",
        "firstname",
        "lastname",
        "allowed_clients"
      ],
      "properties": {
        "email": {
          "type": "string",
          "title": "Email Address"
        },
        "firstname": {
          "type": "string",
          "title": "First Name"
        },
        "lastname": {
          "type": "string",
          "title": "Surname"
        },
        "allowed_clients": {
          "type": "array",
          "title": "Clients",
          "description": "Which clients should this user have access to?",
          "items": {
            "type": "string",
            "enum": Object.keys(clients),
            "enumNames": Object.values(clients).map(x => x['name']),
          },
          "uniqueItems": true
        }
      }
    };

    const uiSchema = {
      "allowed_clients": {
        "ui:widget": "checkboxes"
      }
    };

    return (
      <div>
        <Button id="Popover4" style={{height: '3rem'}} color='primary' onClick={() => this.dialogRef.current.show()}>
          <FontAwesome name='plus' style={{marginRight: 5}}/>New User
        </Button>
        <JSONSchemaDialog ref={this.dialogRef} title='Create New User' schema={schema} uiSchema={uiSchema} onSave={onSave} size='lg' />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  clients: state.clients.clients,
});

const mapDispatchToProps = dispatch => ({
  createTag: (name, source_dimension, options) => dispatch(createTag(name, source_dimension, options)),
  onSave: (user) => dispatch(createUser(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewUserButton);
