import React, {Component} from "react";
import './ColumnSelector.css'
import {arrayMove, SortableContainer, SortableElement} from 'react-sortable-hoc';
import {Input} from "reactstrap";
import FontAwesome from 'react-fontawesome';
import {connect} from "react-redux";

const SortableItem = SortableElement(({value, onRemove}) =>
  <div className='sortable-list-item'>
    <FontAwesome name='grip-lines' />
    {value.display_name}
    <button onClick={(e) => {e.preventDefault(); onRemove()}}><FontAwesome name='times' /></button>
  </div>
);

const SortableList = SortableContainer(({items, onRemove}) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} onRemove={() => onRemove(index)} />
      ))}
    </div>
  );
});

class ColumnSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDimensions: props.dimensions,
      selectedMetrics: props.metrics,
    };
    this.props.onChange(props.dimensions, props.metrics)
  }

  notifyChange = () => {
    this.props.onChange(this.state.selectedDimensions, this.state.selectedMetrics);
  };

  onSortDimensions = ({oldIndex, newIndex}) => {
    this.setState({
      selectedDimensions: arrayMove(this.state.selectedDimensions, oldIndex, newIndex),
    }, this.notifyChange);
  };

  onSortMetrics = ({oldIndex, newIndex}) => {
    this.setState({
      selectedMetrics: arrayMove(this.state.selectedMetrics, oldIndex, newIndex),
    }, this.notifyChange);
  };

  addDimension = (id) => {
    this.setState({
      selectedDimensions: this.state.selectedDimensions.concat([id])
    }, this.notifyChange);
  };

  removeDimension = (index) => {
    if (index >= 0) {
      this.setState({
        selectedDimensions: this.state.selectedDimensions.filter((item, idx) => idx !== index)
      }, this.notifyChange);
    }
  };

  addMetric = (id) => {
    this.setState({
      selectedMetrics: this.state.selectedMetrics.concat([id])
    }, this.notifyChange);
  };

  removeMetric = (index) => {
    if (index >= 0) {
      this.setState({
        selectedMetrics: this.state.selectedMetrics.filter((item, idx) => idx !== index)
      }, this.notifyChange);
    }
  };

  filterResults = (event) => {
    this.setState({
      filterCriteria: event.target.value.toLowerCase()
    })
  };

  searchFilter = (x) => {
    if (!this.state.filterCriteria) {
      return true;
    }

    return x.display_name.toLowerCase().indexOf(this.state.filterCriteria) >= 0;
  };

  render() {
    const {config} = this.props;

    const selectedDimensions = this.state.selectedDimensions.map((x) => config['dimensions'].find((y) => y['id'] === x));
    const selectedMetrics = this.state.selectedMetrics.map((x) => config['metrics'].find((y) => y['id'] === x));

    const availableDimensions = config['dimensions'].filter(this.searchFilter);
    const availableMetrics = config['metrics'].filter(this.searchFilter);

    return (
      <div className='column-list'>
        <div id='left-column'>
          <div className='search-input'>
            <Input type="text" placeholder="Search..." onChange={this.filterResults} style={{marginBottom: 15}} />
          </div>
          <div className='available-columns'>
            <h5>Dimensions</h5>
            { availableDimensions.map((item) =>
              <div key={item.id} className="available-item" onClick={() => this.addDimension(item.id)}>
                <FontAwesome name='plus-circle' />
                { item.display_name }
              </div>
            )}
            { availableDimensions.length === 0 && (
              <span className='none-found'>No matching dimensions found</span>
            )}
            <h5>Metrics</h5>
            { availableMetrics.filter(this.searchFilter).map((item) =>
              <div key={item.id} className="available-item" onClick={() => this.addMetric(item.id)}>
                <FontAwesome name='plus-circle' />
                { item.display_name }
              </div>
            )}
            { availableMetrics.length === 0 && (
              <span className='none-found'>No matching metrics found</span>
            )}
          </div>
        </div>
        <div id='right-column' className='col-5'>
          <h4>Selected Columns</h4>
          <SortableList items={selectedDimensions} onSortEnd={this.onSortDimensions} onRemove={this.removeDimension} />
          <hr />
          <SortableList items={selectedMetrics} onSortEnd={this.onSortMetrics} onRemove={this.removeMetric} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  config: state.config,
  dimensions: state.report.definition.dimensions,
  metrics: state.report.definition.metrics
});

export default connect(mapStateToProps, null)(ColumnSelector);
