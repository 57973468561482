const initialState = {data: null};

const user = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_LOGGING_IN':
      return {
        ...state,
        error: null
      };
    case 'USER_LOGGED_IN':
      return {
        ...state,
        error: null,
        data: action
      };
    case 'USER_LOGIN_FAILED':
      return {
        ...state,
        error: 'Login failed. Please try again.',
        data: null
      };
     case 'USER_LOGGED_OUT':
      return initialState;
    default:
      return state;
  }
};

export default user;
