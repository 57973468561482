import React, {Component} from 'react';
import {injectIntl} from "react-intl";
import {Button, Card, CardBody} from "reactstrap";
import './Settings.css'
import {connect} from "react-redux";
import FontAwesome from "react-fontawesome";
import CustomDimension from "./CustomDimension";
import JSONSchemaDialog from "./JSONSchemaDialog";
import {createCustomDimension} from "../actions";

class CustomDimensionSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.dialogRef = React.createRef();
  }

  render() {
    const {dimensions, createDimension} = this.props;

    const schema = {
      "type": "object",
      "required": [
        "displayName",
      ],
      "properties": {
        "displayName": {
          "type": "string",
          "title": "Display Name"
        },
      }
    };

    const uiSchema = {
    };

    return (
      <div className='row'>
        <div className='col-4 info'>
          <h3>Custom Dimensions</h3>
          <p>Custom dimensions are used to extract information from the standard dimensions such as Campaign Name.
            Custom dimensions are available for selecting in the dashboard report by selecting Edit Columns.</p>
        </div>
        <div className='col-8'>
          <div style={{display: 'flex', 'justifyContent': 'space-between', alignItems: 'center', marginBottom: '1.5rem'}}>
            <div className='section-title'>Custom Dimensions</div>
            <Button color='primary' onClick={() => this.dialogRef.current.show()}>
              <FontAwesome name='plus' style={{marginRight: 5}} />
              Add Custom Dimension
            </Button>
          </div>

          {dimensions.filter(x => x.sources).map(dimension =>
            <Card key={dimension.id}>
              <CardBody>
                <CustomDimension dimension={dimension}/>
              </CardBody>
            </Card>
          )}
        </div>
        <JSONSchemaDialog ref={this.dialogRef} title='Create New Source' schema={schema} uiSchema={uiSchema} onSave={createDimension}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dimensions: state.config.dimensions.filter(x => x.id.indexOf('tag_') !== 0)
});

const mapDispatchToProps = dispatch => ({
  createDimension: (displayName) => dispatch(createCustomDimension(displayName))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CustomDimensionSettings))
