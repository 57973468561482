import React, {Component} from 'react';
import {connect} from 'react-redux'
import {loadChangeHistory} from "../actions";
import './TooltipContent.css'
import {injectIntl} from 'react-intl'
import FontAwesome from 'react-fontawesome';

class TooltipContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: props.date,
      persons: []
    };
  }

  render() {
    const {payload, history, chartMetrics, metrics, intl} = this.props;
    const primaryMetricConfig = metrics.find((x) => {return x.id === chartMetrics.primary});
    const secondaryMetricConfig = metrics.find((x) => {return x.id === chartMetrics.secondary});
    const date = (((payload ? payload[0] : {}) || {}).payload || {}).date;

    function labelFormatter(value) {
      if (value === undefined) {
        return undefined;
      }

      return intl.formatDate(new Date(value), {
        weekday:"short",
        day:"numeric",
        month:"short",
        year:"numeric"});
    }

    function formatter(value, name, props) {
      const metricConfig = secondaryMetricConfig && name === secondaryMetricConfig.display_name ? secondaryMetricConfig : primaryMetricConfig;

      if (metricConfig.style === 'currency') {
        return intl.formatNumber(value, {
          style: 'currency',
          currency: metricConfig.currency
        });
      } else if (metricConfig.style === 'percent') {
        return intl.formatNumber(value, {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      } else {
        return intl.formatNumber(value, {
          minimumFractionDigits: metricConfig.decimals || 0,
          maximumFractionDigits: metricConfig.decimals || 0
        });
      }
    }

    return (
      <div className='tooltip-content'>
        <div>{labelFormatter(date)}</div>
        {(payload || []).map((x, i) => (
          <div key={i} style={{'color': x.color}}>{x.name}: {formatter(x.value, x.name)}</div>
        ))}
        {/*<div className='history'>Change History:</div>*/}
        {/*{ !history[date] && (*/}
          {/*<div><FontAwesome name='spinner' style={{marginRight: 5, color: '#666666'}}/><i>Loading...</i></div>*/}
        {/*)}*/}
        {/*{ history && history[date] && history[date].length === 0 && (*/}
          {/*<ul><li><FontAwesome name='times-circle' style={{marginRight: 5, color: '#ff0000'}}/>No changes</li></ul>*/}
        {/*)}*/}
        {/*<ul>*/}
        {/*{(history[date] || []).map((change, i) => {*/}
          {/*if (i === 15) {*/}
            {/*return <span key={'more'} style={{color: '#666666'}}>More...</span>*/}
          {/*} else if (i < 15) {*/}
            {/*return (*/}
              {/*<li key={i}>*/}
                {/*{change.started && (*/}
                  {/*<FontAwesome name='play' style={{fontSize: '0.85rem', marginRight: 3, color: '#66ff66'}}/>*/}
                {/*)}*/}
                {/*{change.stopped && (*/}
                  {/*<FontAwesome name='pause' style={{fontSize: '0.85rem', marginRight: 3, color: '#ff0000'}}/>*/}
                {/*)}*/}
                {/*<span style={{marginLeft: 5}}>{change.name || 'Unknown'}</span>*/}
              {/*</li>*/}
            {/*)*/}
          {/*} else {*/}
            {/*return null;*/}
          {/*}*/}
        {/*})}*/}
        {/*</ul>*/}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  history: state.spend.history,
  chartMetrics: state.report.definition.chart_metrics,
  metrics: state.config.metrics,
});

const mapDispatchToProps = dispatch => ({
  loadChangeHistory: (date) => dispatch(loadChangeHistory(date))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TooltipContent));
