const config = (state = {saved_reports: [], dimensions: [], metrics: [], cohort_periods: [], settings: {tags: []}}, action) => {
  switch (action.type) {
    case 'INITIALIZE_CONFIG':
      return action.config;
    case 'CUSTOM_DIMENSION_CREATED':
      return {
        ...state,
        dimensions: [action.value, ...state.dimensions],
      };
    default:
      return state;
  }
};

export default config;
