import React, {Component} from 'react';
import './ChartSettings.css'
import {FormGroup, Label, Popover, PopoverBody} from "reactstrap";
import FontAwesome from "react-fontawesome";
import {updateChartMetric} from "../actions";
import Select from "react-select";
import {connect} from "react-redux";

class ChartSettings extends Component {
  constructor() {
    super();

    this.state = {
      popoverOpen: false
    }
  }

  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  };

  render() {
    const {updateChartMetrics, metrics, chartMetrics} = this.props;

    return (
      <div style={{display: 'block', float: 'right', position: 'relative', zIndex: 99}}>
        <button id="chart-settings-button" onClick={this.toggle}>
          <FontAwesome name='cog' style={{marginRight: 5}}/>
        </button>
        <Popover className='chart-settings-popover' placement="left" isOpen={this.state.popoverOpen} target="chart-settings-button" toggle={this.toggle}>
          <PopoverBody>
            <FormGroup>
              <Label for="primary-metric">Primary Metric</Label>
              <Select
                clearable={false}
                name="primary-metric"
                value={chartMetrics.primary}
                options={metrics.map((x) => ({value: x.id, label: x.display_name}))}
                onChange={(option) => updateChartMetrics(option ? option.value : null, chartMetrics.secondary)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="secondary-metric">Secondary Metric</Label>
              <Select
                clearable={true}
                name="secondary-metric"
                value={chartMetrics.secondary}
                options={metrics.map((x) => ({value: x.id, label: x.display_name}))}
                onChange={(option) => updateChartMetrics(chartMetrics.primary, option ? option.value : null)}
              />
            </FormGroup>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  chartMetrics: state.report.definition.chart_metrics,
  metrics: state.config.metrics
});

const mapDispatchToProps = dispatch => ({
  updateChartMetrics: (primary, secondary) => dispatch(updateChartMetric(primary, secondary)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartSettings);