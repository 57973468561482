import React, {Component} from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Card, CardBody, Container, Table} from "reactstrap";
import NavigationContainer from "./NavigationContainer";
import {initializeUserSettings} from "../actions";
import './Users.css'
import NewUserButton from "./NewUserButton";

class Users extends Component {
  componentDidMount() {
    this.props.onInit(this.props.match.params.clientId);
  }

  render() {
    const {users, clients} = this.props;

    return (
      <div id='users'>
        <NavigationContainer showDropdown={true}/>
          <Container fluid={true}>
            <div className='row'>
              <div className='col-4 info'>
                <h3>Users</h3>
                <p>Manage access to Analytics for internal staff members as well as external clients. Only super-admins can create new administrators.</p>
              </div>
              <div className='col-8'>
                <Card>
                  <CardBody>
                    <div className='top-bar'>
                      <div className='section-title'>Users</div>
                      <NewUserButton />
                    </div>
                    <div style={{marginLeft: '-1rem', marginRight: '-1rem'}}>
                      <Table id='users'>
                        <thead>
                        <tr>
                          <th>Email</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Clients</th>
                          <th>Recent Sessions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users.map((user, index) =>
                          <tr key={index}>
                              <td>{user.email}</td>
                              <td>{user.firstname}</td>
                              <td>{user.lastname}</td>
                              <td>
                                {user.admin ?
                                <div>All</div>
                                  :
                                  (user.allowed_clients || []).map((client) =>
                                    <div>{(clients[client] || {}).name}</div>
                                  )
                                }
                              </td>
                              <td>{user.sessions}</td>
                          </tr>
                        )}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Container>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.clients.clients,
  users: state.users
});

const mapDispatchToProps = dispatch => ({
  onInit: (clientId) => dispatch(initializeUserSettings(clientId))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Users))
