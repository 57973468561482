import React, {Component} from 'react';
import {FormattedNumber, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Button, Card, CardBody, Container, Table} from "reactstrap";
import {exportTargets, initializeTargets, loadTargets} from "../actions";
import NavigationContainer from "./NavigationContainer";
import DateRange from "../components/DateRange";
import moment from "moment";
import './Targets.css'
import FontAwesome from "react-fontawesome";

class Targets extends Component {
  constructor() {
    super();
    this.state = {
      dropdownOpen: false,
      startDate: moment().subtract(1, 'days'),
      endDate: moment().subtract(1, 'days')
    }
  }

  componentDidMount() {
    this.props.onInit(this.props.match.params.clientId, this.state.startDate, this.state.endDate);
  }

  updateDateRange = (s, e) => {
    this.setState({
      startDate: moment(s),
      endDate: moment(e)
    }, function() {
      this.props.onDateChange(this.props.match.params.clientId, this.state.startDate, this.state.endDate);
    })
  };

  render() {
    const {targets, client, intl} = this.props;

    function formatter(metricConfig) {
      return (value) => {
        if (metricConfig.style === 'currency') {
          return intl.formatNumber(value, {
            style: 'currency',
            currency: metricConfig.currency
          });
        } else if (metricConfig.style === 'percent') {
          return intl.formatNumber(value, {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
        } else {
          return intl.formatNumber(value, {
            minimumFractionDigits: metricConfig.decimals || 0,
            maximumFractionDigits: metricConfig.decimals || 0
          });
        }
      }
    }

    return (
      <div>
        <NavigationContainer showDropdown={true}/>
        <Container fluid={true} style={{padding: '1rem'}}>
          <Card>
            <CardBody>
          <div className='d-flex justify-content-between'>
            <div className='form-inline'>
              <DateRange
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onApply={this.updateDateRange}
              />
            </div>
            <div className='form-inline'>
              <Button color='primary' onClick={() => this.props.exportTargets(this.props.match.params.clientId, this.state.startDate, this.state.endDate)}>
                <FontAwesome name='download'/> Export Template
              </Button>
            </div>
          </div>
          <div>
            <Table id='targets'>
              <thead>
              <tr>
                {targets.dimensions.map((dimension) =>
                  <th key={dimension.id}>{dimension.display_name}</th>
                )}
                <th>Spend</th>
                {targets.metrics.map((metric) => {
                  return ([
                    <th>{metric.display_name}<br/><small>Target</small></th>,
                    <th style={{verticalAlign: 'bottom'}}><small>Actual</small></th>
                  ])
                })}
              </tr>
              </thead>
              <tbody>
              {targets.values.map((target, index) =>
                <tr key={index}>
                  {targets.dimensions.map((dimension, index) =>
                    <td key={index}>{target[`dimension_0${index + 1}`]}</td>
                  )}
                  <td style={{textAlign: 'right'}}><FormattedNumber style='currency' currency={client.currency} value={(target['actuals'] || {})['spend'] || 0} /></td>
                  {targets.metrics.map((metric, index) => {
                    return ([
                      <td style={{textAlign: 'right'}}><FormattedNumber style={metric['style']} currency={client.currency} value={target[`metric_0${index + 1}`]} minimumFractionDigits={metric['decimals']} maximumFractionDigits={metric['decimals']} /></td>,
                      <td style={{textAlign: 'right'}}><FormattedNumber style={metric['style']} currency={client.currency} value={(target['actuals'] || {})[metric.id] || 0} minimumFractionDigits={metric['decimals']} maximumFractionDigits={metric['decimals']} /></td>
                    ])
                  })}
                </tr>
              )}
              {targets.unmatched_records.map((target, index) =>
                <tr key={index} className='unmatched-record'>
                  {target.dimension_values.map((dimension_value, index) =>
                    <td key={index}>{dimension_value}</td>
                  )}
                  <td style={{textAlign: 'right'}}><FormattedNumber style='currency' currency={client.currency} value={(target['actuals'] || {})['spend'] || 0} /></td>
                  {targets.metrics.map(metric => {
                    return ([
                      <td/>,
                      <td style={{textAlign: 'right'}}><FormattedNumber style={metric['style']} currency={client.currency} value={(target['actuals'] || {})[metric.id] || 0} minimumFractionDigits={metric['decimals']} maximumFractionDigits={metric['decimals']} /></td>
                    ])
                  }
                  )}
                </tr>
              )}
              </tbody>
            </Table>
          </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  targets: state.targets,
  client: state.clients.clients[state.clients.active_client_id]
});

const mapDispatchToProps = dispatch => ({
  onInit: (clientId, startDate, endDate) => dispatch(initializeTargets(clientId, startDate, endDate)),
  onDateChange: (clientId, startDate, endDate) => dispatch(loadTargets(clientId, startDate, endDate)),
  exportTargets: (clientId, startDate, endDate) => dispatch(exportTargets(clientId, startDate, endDate))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Targets))
