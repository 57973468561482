import React, {Component} from 'react';
import {addFilter, sortReport} from "../actions";
import {connect} from "react-redux";
import SimpleDataTable from "../components/SimpleDataTable";

class ReportTable extends Component {
  //TODO: Make this a const instead of a class

  render() {
    const {report, config, onSort, onClickDimension} = this.props;

    return (
      <SimpleDataTable
        dimensions={report.dimensions}
        metrics={report.metrics}
        config={config}
        data={report.data}
        totals={report.totals}
        rowCount={report.row_count}
        sortOrder={report.definition.sort_order}
        pageSize={report.query.page_size}
        loading={report.loading}
        onClickDimension={(field, value) => onClickDimension({field, operator: 'EQUALS', values: [value]})}
        onSort={onSort}/>
    );
  }
}

const mapStateToProps = (state) => ({
  report: state.report,
  config: state.config,
});

const mapDispatchToProps = dispatch => ({
  onClickDimension: (filter) => dispatch(addFilter(filter)),
  onSort: (column) => dispatch(sortReport(column)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportTable)
