import React, {Component} from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Card, CardBody, Container, Table} from "reactstrap";
import NavigationContainer from "./NavigationContainer";
import './Recommendations.css'
import {initializeRecommendations} from "../actions";

class Recommendations extends Component {
  componentDidMount() {
    this.props.onInit(this.props.match.params.clientId);
  }

  render() {
    const {recommendations} = this.props;

    return (
      <div id='recommendations'>
        <NavigationContainer showDropdown={true}/>
        <Container fluid={true}>
          <Card>
            <CardBody>
              <div style={{marginLeft: '-1.25rem', marginRight: '-1.25rem'}}>
                <Table>
                  <thead>
                  <tr>
                    <th>Campaign</th>
                    <th>Ad Group</th>
                    <th>Recommendation</th>
                  </tr>
                  </thead>
                  <tbody>
                  {recommendations.map((recommendation) =>
                    <tr key={recommendation._id}>
                      <td className='campaign'>{recommendation.campaign_name}</td>
                      <td className='adgroup'>{recommendation.adgroup_name}</td>
                      <td className='recommendation'><span dangerouslySetInnerHTML={{__html: recommendation.recommendation}} /></td>
                    </tr>
                  )}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  recommendations: state.recommendations
});

const mapDispatchToProps = dispatch => ({
  onInit: (clientId) => dispatch(initializeRecommendations(clientId))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Recommendations))
