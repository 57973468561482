import React, {Component} from 'react';
import {injectIntl} from "react-intl";
import {Button, Table} from "reactstrap";
import './Settings.css'
import {connect} from "react-redux";
import FontAwesome from "react-fontawesome";
import JSONSchemaDialog from "./JSONSchemaDialog";

class CustomDimension extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.dialogRef = React.createRef();
  }

  render() {
    const {dimension} = this.props;

    const description = (config) => {
      switch (config.type) {
        case 'part':
          return `Split out '${dimension.display_name}' using delimiter '${config.delimiter}' and return part ${config.index + 1}.`;
        case 'regex':
          return `Apply regular expression '${config.regex}' to '${dimension.display_name}' and return the first matching group.`;
        case 'source_value':
          return `Return the unaltered value of '${dimension.display_name}'.`;
        case 'contains':
          let d = Object.entries(config.mappings).map(([k, v]) => `If '${dimension.display_name}' contains '${k}' then return '${v}'.<br/>`).join(' ');
          if (config.default_value) {
            d += ` Otherwise return '${config.default_value}'`
          }
          return d;
        default:
          if (config.default_value) {
            return `Return the value '${config.default_value}'.`
          }
          return config.type
      }
    };

    const schema = {
      // "definitions": {
      //   "Mapping": {
      //     "type": "object",
      //     "properties": {
      //       "Contains": {
      //         "type": "string",
      //       },
      //       "Custom Value": {
      //         "type": "string",
      //       }
      //     },
      //   }
      // },
      "type": "object",
      "anyOf": [
        {
          "title": "Split a dimension by a delimiter and select a part",
          "properties": {
            "Dimension": {
              "type": "string",
              "default": "Yes",
              "enum": [
                "Campaign Name",
                "Ad Group Name",
                "Ad Name"
              ]
            },
            "Delimiter": {
              "type": "string",
              "default": "_",
            },
            "Index": {
              "type": "number",
              "default": "0",
            }
          }
        },
        {
          "title": "Use a regular expression to extract data from another dimension",
          "properties": {
            "Dimension": {
              "type": "string",
              "default": "Yes",
              "enum": [
                "Campaign Name",
                "Ad Group Name",
                "Ad Name"
              ]
            },
            "Regular Expression": {
              "type": "string",
              "default": "(.*)",
            },
          }
        },
        // {
        //   "title": "Choose a custom value depending on the content of another dimension",
        //   "properties": {
        //     "Dimension": {
        //       "type": "string",
        //       "default": "Yes",
        //       "enum": [
        //         "Campaign Name",
        //         "Ad Group Name",
        //         "Ad Name"
        //       ]
        //     },
        //     "Mappings": {
        //       "type": "array",
        //       "items": {
        //         "$ref": "#/definitions/Mapping"
        //       }
        //     },
        //   },
        // }
      ]
    };

    const uiSchema = {

    };

    return (
      <div>
        <div className='top-bar'>
          <div className='section-title'>{dimension.display_name}</div>
        </div>
        <div style={{marginLeft: '-1rem', marginRight: '-1rem'}}>
          <Table>
            <thead>
            <tr>
              <th style={{width: '20%'}}>Source</th>
              <th style={{width: '20%'}}>Source Dimension</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
            {Object.entries(dimension.sources).map(([source, config]) =>
              <tr key={source}>
                <td>{source}</td>
                <td>{dimension.display_name}</td>
                <td><span dangerouslySetInnerHTML={{__html: description(config)}}/></td>
              </tr>
            )}
            { dimension.sources.length === 0 &&
              <tr>
                <td colSpan={3} style={{textAlign: 'center'}}><i>No sources defined. Click 'Add Source' to create one.</i></td>
              </tr>
            }
            </tbody>
          </Table>
          <Button color='secondary' onClick={() => this.dialogRef.current.show()} style={{marginTop: '1rem', marginRight: '1.5rem', float: 'right'}}>
            <FontAwesome name='plus' style={{marginRight: 5}} />
            Add Source
          </Button>
        </div>
        <JSONSchemaDialog ref={this.dialogRef} title='Create New Source' schema={schema} uiSchema={uiSchema} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // dimensions: state.config.dimensions.filter(x => x.id.indexOf('tag_') !== 0)
});

const mapDispatchToProps = dispatch => ({});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CustomDimension))
