/*global ClipboardItem*/

import React, {Component} from 'react';
import {Button, Table} from "reactstrap";
import './SimpleDataTable.css'
import MetricCell from "./DataTableMetricCell";
import DataTableHeaderCell from "./DataTableHeaderCell";
import Thumbnail from "./Thumbnail";
import DrillDown from "./DrillDown";
import html2canvas from "html2canvas";
import FontAwesome from "react-fontawesome";

class SimpleDataTable extends Component {
  COLOURS = [
    'linear-gradient(to right, rgba(103,235,250,0.1), rgba(103,235,250,0.4))',
    'linear-gradient(to right, rgba(253,223,126,0.1), rgba(253,223,126,0.4))',
    'linear-gradient(to right, rgba(250,169,157,0.1), rgba(250,169,157,0.4))',
    'linear-gradient(to right, rgba(255,190,255,0.1), rgba(255,190,255,0.4))',
    'linear-gradient(to right, rgba(166,255,200,0.1), rgba(166,255,200,0.4))',
  ];

  constructor(props) {
    super(props);

    this.state = {
      conditionally_format_indexes: new Array(props.metrics.length),
      showSnapshotButton: false
    }
  }

  clickHeader = (e, col, idx) => {
    if (e.altKey) {
      const currentIndex = this.state.conditionally_format_indexes[idx - this.props.dimensions.length];
      const nextIndex = currentIndex === undefined ? 0 : (currentIndex === this.COLOURS.length - 1 ? undefined : currentIndex + 1);

      this.setState({
        conditionally_format_indexes: Object.assign([], this.state.conditionally_format_indexes, {[idx - this.props.dimensions.length]: nextIndex})
      });
    } else {
      this.props.onSort(col);
    }
  };

  snapshot = () => {
    const element = document.getElementById('pivot-table');
    html2canvas(element, {scrollX: 0, scrollY: -window.scrollY}).then(function(canvas) {
      canvas.toBlob(async blob =>
        await navigator.clipboard.write([
          new ClipboardItem({
            'image/png': blob
          })
        ])
      )
    });
    this.setState({
      showSnapshotButton: false
    })
  };

  keyPressed = (event) => {
    if(event.keyCode === 18) {
      this.setState({
        showSnapshotButton: true
      })
    }
  };

  keyReleased = (event) => {
    if(event.keyCode === 18) {
      this.setState({
        showSnapshotButton: false
      })
    }
  };

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      conditionally_format_indexes: new Array(props.metrics.length),
    });
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keyPressed, false);
    document.addEventListener("keyup", this.keyReleased, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyPressed, false);
    document.removeEventListener("keyup", this.keyReleased, false);
  }

  render() {
    const column_map = {};

    const all_columns = [].concat(
      ...this.props.config.dimensions,
      ...this.props.config.metrics
    );

    for (let obj of all_columns) {
      column_map[obj.id] = obj;
    }

    const max_values = [...Array(this.state.conditionally_format_indexes.length).keys()].map((idx) => {
      return Math.max(...this.props.data.map(o => o[this.props.dimensions.length + idx]), 0);
    });

    return (
      <div id='pivot-table-wrapper' style={{overflowX: 'scroll'}}>
        <div style={{display: this.props.loading ? 'block' : 'none'}} className='loading-overlay'>
          <img src={'/spinner.gif'} style={{}} alt='Spinner'/>
        </div>
        <Table id='pivot-table'>
          <thead>
          <tr>
            {
              this.props.dimensions.map((dimension) => {
                  const sortIndex = this.props.sortOrder.findIndex(item => item.id === dimension);
                  const sortDirection = sortIndex >= 0 ? this.props.sortOrder[sortIndex].order : '';
                  return <th className='dimension' key={dimension}>
                    <DataTableHeaderCell displayName={column_map[dimension].display_name} sortIndex={sortIndex}
                                         sortDirection={sortDirection} onClick={(e) => this.clickHeader(e, dimension)}
                                         sortOrder={null}/>
                  </th>
                }
              )}
            {this.props.metrics.map((metric, i) => {
                const sortIndex = this.props.sortOrder.findIndex(item => item.id === metric);
                const sortDirection = sortIndex >= 0 ? this.props.sortOrder[sortIndex].order : '';
                return <th className={i === 0 ? 'metric first' : 'metric'} key={metric}>
                  <DataTableHeaderCell displayName={(column_map[metric] || {}).short_name}
                                       sortIndex={sortIndex}
                                       sortDirection={sortDirection}
                                       onClick={(e) => this.clickHeader(e, metric, this.props.dimensions.length + i)}
                                       sortOrder={null}/>
                </th>
              }
            )}
          </tr>
          </thead>
          <tbody>
          {this.props.data.map((record, i) =>
            <tr key={i}>
              {record.slice(0, this.props.dimensions.length).map((cell, j) => (
                <td className='dimension' key={j}>
                    { this.props.dimensions[j] === 'creative_url' ?
                      <Thumbnail url={cell} />
                    :
                      <span>
                        <span className='dimension-value' onClick={() => this.props.onClickDimension(this.props.dimensions[j], cell)}>{cell}</span>
                        <DrillDown dimensionId={this.props.dimensions[j]} dimensionValue={cell} />
                      </span>
                    }
                </td>
              ))}
              {record.slice(this.props.dimensions.length, record.length).map((cell, j) => (
                <td className={j === 0 ? 'metric first' : 'metric'} key={j}>
                  {this.state.conditionally_format_indexes[j] !== undefined && (
                    <div className='data-bar' style={{width: `${cell / max_values[j] * 100}%`, backgroundImage: this.COLOURS[this.state.conditionally_format_indexes[j]]}}/>
                  )}
                  <MetricCell column={column_map[this.props.metrics[j]] || {}} value={cell}/>
                </td>
              ))}
            </tr>
          )}
          </tbody>
          <tfoot>
          <tr>
            <td colSpan={this.props.dimensions.length}>Total</td>
            {this.props.totals.map((total, i) =>
              <td key={i} className={i === 0 ? 'metric first' : 'metric'}>
                <MetricCell column={column_map[this.props.metrics[i]] || {}} value={total}/>
              </td>
            )}
          </tr>
          </tfoot>
        </Table>
        <div id='tools'>
          <Button id='snapshot-button' color='secondary' onClick={this.snapshot} style={{'display': this.state.showSnapshotButton ? 'block': 'none'}}>
            <FontAwesome name='camera' />
          </Button>
        </div>
      </div>
    );
  }
}

export default SimpleDataTable;