import axios from "axios/index";
import * as fileSaver from "file-saver";
import history from '../history'
import queryString from 'query-string';
import {toastr} from 'react-redux-toastr';

export const initializeHome = (clientId) => (dispatch) => {
  axios.get('/api/clients')
    .then(response => {
        dispatch({type: 'INITIALIZE_CLIENTS', clients: response.data});
        dispatch(loadClientStats());
      }
    )
    .catch((error) => {
      if (401 === error.response.status) {
        dispatch(logout());
      } else {
        toastr.error('An error occurred. Please try again.');
      }
    });
};

export const initializeTagging = (clientId) => (dispatch, getState) => {
  axios.get('/api/clients')
    .then(response => {
        dispatch({type: 'INITIALIZE_CLIENTS', clients: response.data});
        dispatch(selectClient(clientId));
        axios.get(`/api/${clientId}/config`)
          .then(response => {
              dispatch({type: 'INITIALIZE_CONFIG', config: response.data});
              const tags = getState().config.settings.tags;

              if (tags.length > 0) {
                dispatch(selectTag(tags[0]))
              }
            }
          )
          .catch((error) => {
            if (401 === error.response.status) {
              dispatch(logout());
            } else {
              toastr.error('An error occurred. Please try again.');
            }
          });
      }
    )
    .catch((error) => {
      if (401 === error.response.status) {
        dispatch(logout());
      } else {
        toastr.error('An error occurred. Please try again.');
      }
    });
};

export const initializeRecommendations = (clientId) => (dispatch, getState) => {
  axios.get('/api/clients')
    .then(response => {
        dispatch({type: 'INITIALIZE_CLIENTS', clients: response.data});
        dispatch(selectClient(clientId));
        dispatch(loadRecommendations(clientId));
      }
    )
    .catch((error) => {
      if (401 === error.response.status) {
        dispatch(logout());
      } else {
        toastr.error('An error occurred. Please try again.');
      }
    });
};

export const initializeSettings = (clientId) => dispatch => {
  axios.get('/api/clients')
    .then(response => {
        dispatch({type: 'INITIALIZE_CLIENTS', clients: response.data});
        dispatch(selectClient(clientId));
        dispatch(loadConfig(clientId));
      }
    )
    .catch((error) => {
      if (401 === error.response.status) {
        dispatch(logout());
      } else {
        toastr.error('An error occurred. Please try again.');
      }
    });
};

export const initializeUserSettings = (clientId) => dispatch => {
  axios.get('/api/clients')
    .then(response => {
        dispatch({type: 'INITIALIZE_CLIENTS', clients: response.data});
        dispatch(loadUsers());
      }
    )
    .catch((error) => {
      if (401 === error.response.status) {
        dispatch(logout());
      } else {
        toastr.error('An error occurred. Please try again.');
      }
    });
};

export const loadUsers = () => dispatch => {
  axios.get(`/api/users`)
    .then(response => {
        dispatch({type: 'INITIALIZE_USERS', users: response.data});
      }
    )
    .catch((error) => {
      if (401 === error.response.status) {
        dispatch(logout());
      } else {
        toastr.error('An error occurred. Please try again.');
      }
    });
};

export const loadConfig = (clientId) => dispatch => {
  axios.get(`/api/${clientId}/config`)
    .then(response => {
        dispatch({type: 'INITIALIZE_CONFIG', config: response.data});
      }
    )
    .catch((error) => {
      if (401 === error.response.status) {
        dispatch(logout());
      } else {
        toastr.error('An error occurred. Please try again.');
      }
    });
};

export const loadRecommendations = (clientId) => dispatch => {
  axios.get(`/api/${clientId}/recommendations`)
    .then(response => {
        dispatch({type: 'UPDATE_RECOMMENDATIONS', recommendations: response.data});
      }
    )
    .catch((error) => {
      if (401 === error.response.status) {
        dispatch(logout());
      } else {
        toastr.error('An error occurred. Please try again.');
      }
    });
};

export const selectTag = (tag) => (dispatch, getState) => {
  dispatch({type: 'SELECT_TAG', tag: tag});
  dispatch(loadTagReport());
};

export const loadTagReport = () => (dispatch, getState) => {
  let tagId = getState().tagging.currentTag.id;
  let clientId = getState().clients.active_client_id;
  const query = getState().tagging.query;

  axios.post(`/api/${clientId}/tags/${tagId}/report`, query)
    .then(response => {
        dispatch({type: 'RECEIVED_TAG_REPORT_PAGE', data: response.data});
      }
    )
    .catch((error) => {
      if (401 === error.response.status) {
        dispatch(logout());
      } else {
        toastr.error('An error occurred. Please try again.');
      }
    });
};

export const loadClientStats = () => (dispatch, getState) => {
  for (let client of Object.values(getState().clients.clients)) {
    axios.get(`/api/clients/${client._id}/stats`)
      .then(response => {
          dispatch({type: 'RECEIVE_CLIENT_STATS', stats: response.data});
        }
      )
      .catch((error) => {
        if (401 === error.response.status) {
          dispatch(logout());
        } else {
          toastr.error('An error occurred. Please try again.');
        }
      });
  }
};

export const initializeTargets = (clientId, startDate, endDate) => (dispatch) => {
  axios.get('/api/clients')
    .then(response => {
        dispatch({type: 'INITIALIZE_CLIENTS', clients: response.data});
        dispatch(selectClient(clientId));
        dispatch(loadTargets(clientId, startDate, endDate));
      }
    )
    .catch((error) => {
      if (401 === error.response.status) {
        dispatch(logout());
      } else {
        toastr.error('An error occurred. Please try again.');
      }
    });
};

export const initializeDashboard = (clientId, reportId, query) => (dispatch) => {
  axios.get('/api/clients')
    .then(response => {
        dispatch({type: 'INITIALIZE_CLIENTS', clients: response.data});
        dispatch(selectClient(clientId));
        dispatch(initializeReport(reportId, query));
        dispatch(loadRecommendations(clientId));
      }
    )
    .catch((error) => {
      if (401 === error.response.status) {
        dispatch(logout());
      } else {
        toastr.error('An error occurred. Please try again.');
      }
    });
};

export const loadTargets = (clientId, startDate, endDate) => (dispatch) => {
  dispatch({type: 'SELECT_CLIENT', clientId});
  axios.get(`/api/${clientId}/targets`, {params: {'start_date': startDate.format('YYYY-MM-DD'), 'end_date': endDate.format('YYYY-MM-DD')}})
    .then(response => {
        dispatch({type: 'UPDATE_TARGETS', targets: response.data});
      }
    )
    .catch((error) => {
      if (401 === error.response.status) {
        dispatch(logout());
      } else if (404 !== error.response.status) {
        toastr.error('An error occurred. Please try again.');
      }
    });
};

export const exportTargets = (clientId, startDate, endDate) => {
  return function (dispatch) {
    axios.get(`/api/${clientId}/targets/export`, {params: {'start_date': startDate.format('YYYY-MM-DD'), 'end_date': endDate.format('YYYY-MM-DD')}})
      .then(response => {
          let data = response.data; //s2ab(action.response.data);
          fileSaver.saveAs(new Blob([data], {type: "text/csv"}), "targets.csv");
        }
      )
      .catch((error) => {
        if (401 === error.response.status) {
          dispatch(logout());
        } else {
          toastr.error('An error occurred. Please try again.');
        }
      })
  }
};

export const refreshReport = (clientId, reportId) => dispatch => {
  axios.get(`/api/${clientId}/config`)
    .then(response => {
        dispatch({type: 'INITIALIZE_CONFIG', config: response.data});
        dispatch(runReport());
        dispatch(updateChart());
      }
    )
    .catch((error) => {
      if (401 === error.response.status) {
        dispatch(logout());
      } else {
        toastr.error('An error occurred. Please try again.');
      }
    });
};

export const initializeReport = (reportId, query) => (dispatch, getState) => {
  let clientId = getState().clients.active_client_id;
  axios.get(`/api/${clientId}/config`)
    .then(response => {
        dispatch({type: 'INITIALIZE_CONFIG', config: response.data});

        if (reportId === undefined) {
          const defaultReportId = getState().config.saved_reports[0]._id;
          history.push(`/${clientId}/${defaultReportId}`);
          dispatch(selectReport(defaultReportId));
          return;
        }

        dispatch({type: 'UPDATE_REPORT_QUERY', query});
        dispatch(selectReport(reportId));
      }
    )
    .catch((error) => {
      if (401 === error.response.status) {
        dispatch(logout());
      } else {
        toastr.error('An error occurred. Please try again.');
      }
    });
};

export const loginFailed = () => ({
  type: 'USER_LOGIN_FAILED'
});

export const login = (email, password) => dispatch => {
  dispatch({
    type: 'USER_LOGGING_IN'
  });

  axios.post('/api/login', {username: email, password: password})
    .then(response => {
        dispatch({type: 'USER_LOGGED_IN', ...response.data});

        const urlParams = new URLSearchParams(window.location.search);
        const redirectParam = urlParams.get('redirect')
        const redirectPath = redirectParam && redirectParam.startsWith('/login') ? '' : urlParams.get('redirect');
        history.push(redirectPath || '/')
      }
    )
    .catch((error) => {
      if (401 === error.response.status) {
        dispatch(loginFailed());
      } else {
        toastr.error('An error occurred. Please try again.');
      }
    });
};

export const saveReport = () => (dispatch, getState) => {
  const clients = getState().clients;
  const report = getState().report;

  axios.put(`/api/${clients.active_client_id}/reports/${report.definition._id}`)
    .then(response => {
      toastr.success('Report saved successfully');
      reloadDashboard(getState, response.data.report_id)
    })
    .catch((error) => {
      if (401 === error.response.status) {
        dispatch(logout());
      } else {
        toastr.error('An error occurred. Please try again.');
      }
    });
};

export const deleteReport = () => (dispatch, getState) => {
  const clients = getState().clients;
  const report = getState().report;

  //TODO: Error handling
  axios.delete(`/api/${clients.active_client_id}/reports/${report.definition._id}`)
    .then(response => {
      toastr.success('Report deleted successfully');
      history.push(`/${clients.active_client_id}`);
    })
    .catch((error) => {
      if (401 === error.response.status) {
        dispatch(logout());
      } else {
        toastr.error('An error occurred. Please try again.');
      }
    });
};

export const saveReportAs = (name) => (dispatch, getState) => {
  const clients = getState().clients;
  const report = getState().report;

  axios.post(`/api/${clients.active_client_id}/reports/${report.definition._id}`, {name})
    .then(response => {
        toastr.success('Report saved successfully');
        reloadDashboard(getState, response.data.report_id);
      }
    )
    .catch((error) => {
      if (401 === error.response.status) {
        dispatch(logout());
      } else {
        toastr.error('An error occurred. Please try again.');
      }
    });
};

export const logout = () => dispatch => {
  dispatch({
    type: 'USER_LOGGING_OUT'
  });
  axios.post(`/api/logout`)
    .then(response => {
        dispatch({type: 'USER_LOGGED_OUT'});
        history.push('/login?redirect=' + encodeURIComponent(window.location.pathname + window.location.search));
      }
    )
};

export const drillDown = (dimensionId, dimensionValue, subDimensionId) => {
  return function (dispatch, getState) {
    const clientId = getState().clients.active_client_id;
    const metrics = getState().report.metrics;
    const dimensions = getState().report.dimensions.map((item, index) => {
      return item === dimensionId ? subDimensionId : item;
    });
    const updatedFilters = [...getState().report.definition.filters, {field: dimensionId, operator: 'EQUALS', values: [dimensionValue]}];

    axios.post(`/api/${clientId}/reports/${getState().report.definition._id}/filters`, updatedFilters)
      .then(response => {
        const reportId = response.data.report_id;
        axios.post(`/api/${clientId}/reports/${reportId}/columns`, {dimensions, metrics})
          .then(response => {
            reloadDashboard(getState, response.data.report_id);
          })
          .catch((error) => {
            if (401 === error.response.status) {
              dispatch(logout());
            } else {
              toastr.error('An error occurred. Please try again.');
            }
          })
      })
      .catch((error) => {
        if (401 === error.response.status) {
          dispatch(logout());
        } else {
          toastr.error('An error occurred. Please try again.');
        }
      });
  }
};

export const addFilter = (filter) => {
  if (filter.field === 'date') {
    // Update date range instead of filtering
    return updateDateRange(filter.value, filter.value)
  } else {
    return function (dispatch, getState) {
      const updatedFilters = [...getState().report.definition.filters, filter];
      dispatch(updateFilters(updatedFilters));
    }
  }
};

function reloadDashboard(getState, reportId) {
  const clientId = getState().clients.active_client_id;
  const actualReportId = reportId || getState().report.definition._id;

  history.push({
    pathname: `/${clientId}/${actualReportId}`,
    search: queryString.stringify(getState().report.query)
  })
}

export const updateFilters = (filters) => {
  return function (dispatch, getState) {
    const clientId = getState().clients.active_client_id;
    const reportId = getState().report.definition._id;

    axios.post(`/api/${clientId}/reports/${reportId}/filters`, filters)
      .then(response => {
        reloadDashboard(getState, response.data.report_id);
      })
      .catch((error) => {
        if (401 === error.response.status) {
          dispatch(logout());
        } else {
          toastr.error('An error occurred. Please try again.');
        }
      })
  }
};

export const createCustomDimension = (dimension) => {
  return function (dispatch, getState) {
    const clientId = getState().clients.active_client_id;

    return axios.post(`/api/${clientId}/config/custom_dimensions`, dimension)
      .then(response => {
        dispatch({type: 'CUSTOM_DIMENSION_CREATED', value: response.data})
      })
      .catch((error) => {
        if (401 === error.response.status) {
          dispatch(logout());
        } else {
          toastr.error('An error occurred. Please try again.');
        }
      })
  }
};

export const createTagOptionValue = (tagId, option, value) => {
  return function (dispatch, getState) {
    const clientId = getState().clients.active_client_id;

    axios.post(`/api/${clientId}/config/settings/tags/${tagId}/options`, {option, value})
      .then(response => {
        dispatch({type: 'UPDATE_TAG_OPTION_VALUE', value: value, tag: option})
      })
      .catch((error) => {
        if (401 === error.response.status) {
          dispatch(logout());
        } else {
          toastr.error('An error occurred. Please try again.');
        }
      })
  }
};

export const createUser = (user) => {
  return function (dispatch, getState) {
    const clientId = getState().clients.active_client_id;

    return axios.post(`/api/users`, user)
      .then(response => {
        dispatch(loadUsers())
      })
  }
};

export const updateTagFilter = (untaggedOnly) => dispatch => {
  dispatch({type: 'UPDATE_TAG_FILTER', untaggedOnly: untaggedOnly});
  dispatch(loadTagReport());
};

export const createTag = (display_name, source_dimension, options) => {
  return function (dispatch, getState) {
    const clientId = getState().clients.active_client_id;

    axios.post(`/api/${clientId}/config/settings/tags`, {display_name, source_dimension, options})
      .then(response => {
        dispatch(initializeSettings(clientId));
      })
      .catch((error) => {
        if (401 === error.response.status) {
          dispatch(logout());
        } else {
          toastr.error('An error occurred. Please try again.');
        }
      })
  }
};

export const updateTagSourceDimension = (tag_id, source_dimension) => {
  return function (dispatch, getState) {
    const clientId = getState().clients.active_client_id;

    axios.post(`/api/${clientId}/config/settings/tags/${tag_id}/source_dimension`, {value: source_dimension})
      .then(response => {
        dispatch(initializeSettings(clientId));
      })
      .catch((error) => {
        if (401 === error.response.status) {
          dispatch(logout());
        } else {
          toastr.error('An error occurred. Please try again.');
        }
      })
  }
};

export const deleteTag = (tag_id) => {
  return function (dispatch, getState) {
    const clientId = getState().clients.active_client_id;

    axios.delete(`/api/${clientId}/config/settings/tags/${tag_id}`)
      .then(response => {
        dispatch(initializeSettings(clientId));
      })
      .catch((error) => {
        if (401 === error.response.status) {
          dispatch(logout());
        } else {
          toastr.error('An error occurred. Please try again.');
        }
      })
  }
};

export const updateTag = (tag_id, name, source_dimension, options) => {
  return function (dispatch, getState) {
    const clientId = getState().clients.active_client_id;

    axios.put(`/api/${clientId}/config/settings/tags/${tag_id}`, {name, source_dimension, options})
      .then(response => {
        dispatch(initializeSettings(clientId));
      })
      .catch((error) => {
        if (401 === error.response.status) {
          dispatch(logout());
        } else {
          toastr.error('An error occurred. Please try again.');
        }
      })
  }
};

export const updateColumns = (dimensions, metrics) => {
  return function (dispatch, getState) {
    const clientId = getState().clients.active_client_id;
    const reportId = getState().report.definition._id;

    axios.post(`/api/${clientId}/reports/${reportId}/columns`, {dimensions, metrics})
      .then(response => {
        reloadDashboard(getState, response.data.report_id);
      })
      .catch((error) => {
        if (401 === error.response.status) {
          dispatch(logout());
        } else {
          toastr.error('An error occurred. Please try again.');
        }
      })
  }
};

export const updateDateRange = (startDate, endDate) => {
  return function (dispatch, getState) {
    dispatch({type: 'UPDATE_DATE_RANGE', startDate, endDate});
    reloadDashboard(getState)
  }
};

export const updateGroupCreatives = (groupCreatives) => {
  return function (dispatch, getState) {
    const clientId = getState().clients.active_client_id;
    const reportId = getState().report.definition._id;

    axios.post(`/api/${clientId}/reports/${reportId}/groupcreatives`, {'value': groupCreatives})
      .then(response => {
        reloadDashboard(getState, response.data.report_id);
      })
      .catch((error) => {
        if (401 === error.response.status) {
          dispatch(logout());
        } else {
          toastr.error('An error occurred. Please try again.');
        }
      })
  }
};

export const sortReport = (column) => {
  return function (dispatch, getState) {
    const clientId = getState().clients.active_client_id;
    const reportId = getState().report.definition._id;

    axios.post(`/api/${clientId}/reports/${reportId}/sort`, {column})
      .then(response => {
        reloadDashboard(getState, response.data.report_id);
      })
      .catch((error) => {
        if (401 === error.response.status) {
          dispatch(logout());
        } else {
          toastr.error('An error occurred. Please try again.');
        }
      })
  }

};

export const updateChartMetric = (primary, secondary) => {
  return function (dispatch, getState) {
    const clientId = getState().clients.active_client_id;
    const reportId = getState().report.definition._id;

    axios.post(`/api/${clientId}/reports/${reportId}/chart/metrics`, {primary, secondary})
      .then(response => {
        reloadDashboard(getState, response.data.report_id);
      })
      .catch((error) => {
        if (401 === error.response.status) {
          dispatch(logout());
        } else {
          toastr.error('An error occurred. Please try again.');
        }
      })
  }
};

export const selectClient = (clientId) => {
  return function (dispatch, getState) {
    dispatch({type: 'SELECT_CLIENT', clientId});
  }
};

export const updateChart = () => {
  return function (dispatch, getState) {
    const query = getState().report.query;
    axios.get(`/api/${getState().clients.active_client_id}/reports/${getState().report.definition._id}/chart`,
      {params: {'start_date': query.start_date, 'end_date': query.end_date}})
      .then(response =>
        dispatch({type: 'RECEIVE_SPEND_DATA', ...response.data})
      )
      .catch((error) => {
        if (401 === error.response.status) {
          dispatch(logout());
        } else {
          toastr.error('An error occurred. Please try again.');
        }
      })
  }
};

export const loadUser = () => {
  return function (dispatch, getState) {
    const query = getState().report.query;
    axios.get(`/api/me`)
      .then(response =>
        dispatch({type: 'USER_LOGGED_IN', ...response.data})
      )
      .catch((error) => {
        if (401 === error.response.status) {
          // dispatch(logout());
        } else {
          toastr.error('An error occurred. Please try again.');
        }
      })
  }
};

export const changeClient = (client) => {
  return function (dispatch, getState) {
    history.push(`/${client}`);
    dispatch(initializeDashboard(client));
  }
};

export const selectReport = (reportId) => {
  return function (dispatch, getState) {
    const currentReportId = getState().report.definition._id;

    axios.get(`/api/${getState().clients.active_client_id}/reports/${reportId}`)
      .then(response => {
        dispatch({type: 'SELECT_REPORT', ...response.data});

        if (currentReportId !== undefined && currentReportId !== reportId) {
          dispatch({type: 'RESET_PAGINATION'});
          dispatch({type: 'RESET_CHANGE_HISTORY'});
        }

        dispatch(updateReport());
        dispatch(updateChart());
      })
      .catch((error) => {
        if (401 === error.response.status) {
          dispatch(logout());
        } else {
          toastr.error('An error occurred. Please try again.');
        }
      })
  }
};

export const updatePageSize = (value) => {
  return function (dispatch) {
    dispatch({type: 'UPDATE_PAGE_SIZE', value});
    dispatch(updateReport());
  }
};

export const updateTaggingPageSize = (value) => {
  return function (dispatch) {
    dispatch({type: 'UPDATE_TAGGING_PAGE_SIZE', value});
    dispatch(loadTagReport());
  }
};

export const selectPage = (index) => {
  return function (dispatch, getState) {
    dispatch({type: 'UPDATE_REPORT_PAGE_INDEX', index});
    reloadDashboard(getState)
  }
};

export const selectTaggingPage = (index) => {
  return function (dispatch) {
    dispatch({type: 'UPDATE_TAGGING_PAGE_INDEX', index});
    dispatch(loadTagReport());
  }
};

export const runReport = () => {
  return function (dispatch) {
    dispatch({type: 'RESET_PAGINATION'});
    dispatch(updateReport());
  }
};

export const updateReport = () => {
  return function (dispatch, getState) {
    dispatch({type: 'REQUEST_REPORT_DATA'});

    const clients = getState().clients;

    axios.post(`/api/${clients.active_client_id}/reports/${getState().report.definition._id}/run`, getState().report.query)
      .then(response =>
        dispatch(receiveReportData(response.data))
      )
      .catch((error) => {
        if (401 === error.response.status) {
          dispatch(logout());
        } else {
          toastr.error('An error occurred. Please try again.');
        }
      })
  }
};

export const loadChangeHistory = (date) => {
  return function (dispatch, getState) {
    const clients = getState().clients;
    const report = getState().report;

    if (!getState().spend.history[date]) {
      axios.get(`/api/${clients.active_client_id}/reports/${report.definition._id}/history/${date}`)
        .then(response =>
          dispatch({type: 'RECEIVE_CHANGE_HISTORY', date, value: response.data})
        )
        .catch((error) => {
          if (401 === error.response.status) {
            dispatch(logout());
          } else {
            toastr.error('An error occurred. Please try again.');
          }
        })
    }
  }
};

export const exportReport = () => {
  return function (dispatch, getState) {
    const clients = getState().clients;
    const report = getState().report;

    axios.post(`/api/${clients.active_client_id}/reports/${report.definition._id}/export`, getState().report.query)
      .then(response => {
          let data = response.data; //s2ab(action.response.data);
          fileSaver.saveAs(new Blob([data], {type: "text/csv"}), "export.csv");
        }
      )
      .catch((error) => {
        if (401 === error.response.status) {
          dispatch(logout());
        } else {
          toastr.error('An error occurred. Please try again.');
        }
      })
  }
};

export const receiveReportData = (data) => ({
  type: 'RECEIVE_REPORT_DATA',
  data
});