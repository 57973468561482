import React from 'react';
import './index.css';
import {Provider} from "react-redux";
import {applyMiddleware, createStore} from "redux";
import createRootReducer from './reducers';
import thunkMiddleware from 'redux-thunk'
import {createLogger} from 'redux-logger'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import {ConnectedRouter, routerMiddleware} from "connected-react-router";
import App from "./containers/App";
import {IntlProvider} from "react-intl";
import history from './history';
import ReduxToastr from "react-redux-toastr";
import ReactDOM from "react-dom";

const persistConfig = {
  key: 'root',
  whitelist: ['user']
};


let middleware;

const forceLogging = new URL(window.location.href).searchParams.get('forceLogging');

if (process.env.NODE_ENV !== 'production' || forceLogging) {
  middleware = applyMiddleware(
    routerMiddleware(history),
    thunkMiddleware,
    createLogger()
  );
} else {
  middleware = applyMiddleware(
    routerMiddleware(history),
    thunkMiddleware
  );
}

const store = createStore(
  createRootReducer(history),
  middleware
);

ReactDOM.render(
  <IntlProvider locale='en-US'>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <div>
          <App/>
          <ReduxToastr
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            closeOnToastrClick
          />
        </div>
      </ConnectedRouter>
    </Provider>
  </IntlProvider>,
  document.getElementById('root')
);
