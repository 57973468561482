import React, {Component} from 'react';
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Select from "react-select";
import ReactTags from 'react-tag-autocomplete';

class TagSettingsDialog extends Component {
  constructor(props) {
    super(props);

    const tag = props.tag || {};

    this.state = {
      name: tag.display_name || '',
      source_dimension: tag.source_dimension || 'ad_name',
      options: Object.keys(tag.values || {}).map(x => {return {'name': x}})
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const tag = nextProps.tag || {};

    this.setState({
      name: tag.display_name || '',
      source_dimension: tag.source_dimension || 'ad_name',
      options: Object.keys(tag.values || {}).map(x => {return {'name': x}})
    });
  }

  handleDelete = (i) => {
    const options = this.state.options.slice(0);
    options.splice(i, 1);
    this.setState({ options });
  };

  handleAddition = (value) => {
    const options = [].concat(this.state.options, value);
    this.setState({ options });
  };

  render() {
    const {dimensions, isOpen, toggle, onSave} = this.props;

    return (
      <Modal size='md' centered={true} isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={this.toggle}>Create Tag</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="display_name" sm={4}>Tag Name</Label>
              <Col sm={8}>
                <Input type="text" name="display_name" id="display_name" value={this.state.name} onChange={event => this.setState({'name': event.target.value})} placeholder="Enter name" required={true} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="source_dimension" sm={4}>Source Dimension</Label>
              <Col sm={8}>
                <Select
                  clearable={false}
                  isSearchable={false}
                  value={this.state.source_dimension}
                  onChange={(selectedOption) => this.setState({source_dimension: selectedOption.value})}
                  name="source_dimension"
                  options={dimensions.map((x) => ({value: x.id, label: x.display_name}))}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="options" sm={4}>Options</Label>
              <Col sm={8}>
                <ReactTags
                  tags={this.state.options}
                  placeholder='Enter value'
                  allowNew={true}
                  addOnBlur={true}
                  handleDelete={this.handleDelete}
                  handleAddition={this.handleAddition} />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
          <Button color="primary" onClick={() => onSave(this.state.name, this.state.source_dimension, this.state.options.map(x => x.name))}>Apply</Button>{' '}
        </ModalFooter>
      </Modal>
    )
  }
}

export default TagSettingsDialog;
