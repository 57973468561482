import React, {Component} from 'react';
import {connect} from "react-redux";
import {login} from "../actions";
import {Alert, Button, Card, CardBody, CardHeader, Form, FormGroup, Input} from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import './Login.css'

class Login extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.login(this.email.value, this.password.value)
  };

  render() {
    return (
      <div id='login-bg'>
        <div className='wrapper'>
          <img src={'/logo.png'} alt='Logo'/>
          <Card>
            <CardHeader tag="h5">
              <span className='tab'>Log in</span>
            </CardHeader>
            <CardBody>
              <Alert color="danger" style={{display: this.props.error != null ? 'block' : 'none'}}>
                <FontAwesome name='exclamation-triangle'/> {this.props.error}
              </Alert>
              <Form id='login-form' onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Input type="email" placeholder='Email' innerRef={(input) => (this.email = input)} />
                </FormGroup>
                <FormGroup>
                  <Input type="password" placeholder='Password' innerRef={(input) => (this.password = input)} />
                </FormGroup>
                <hr />
                <Button color='primary' className='pull-right'>Log in</Button>
              </Form>
            </CardBody>
          </Card>

        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  error: state.user.error
});

export default connect(mapStateToProps, {login})(Login);
