import React from 'react';
import "./Tabs.css";

export const Tabs = ({children}) => (
  <div className='tabs'>
    {children}
    <div className='tab-filler'/>
  </div>
);

export const Tab = ({active, children}) => (
  <div className={active ? 'tab active' : 'tab'}>{children}</div>
);
