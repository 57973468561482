import React, {Component} from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Button, Card, CardBody, Container, InputGroup, InputGroupText, Table} from "reactstrap";
import NavigationContainer from "./NavigationContainer";
import './Tagging.css'
import {createTagOptionValue, initializeTagging, selectTag, updateTagFilter} from "../actions";
import Select from "react-select";
import FontAwesome from 'react-fontawesome';
import TaggingPagination from "./TaggingPagination";

class Tagging extends Component {
  componentDidMount() {
    this.props.onInit(this.props.match.params.clientId);
  }

  render() {
    const {page, query, tags, currentTag, selectTag, createTagOptionValue, updateTagFilter} = this.props;

    return (
      <div id='tagging'>
        <NavigationContainer showDropdown={true}/>
        <Container fluid={true} style={{padding: '1rem'}}>
          <Card>
            <CardBody>
            <div className='d-flex justify-content-between'>
              <div className='form-inline'>
                <InputGroup>
                  <InputGroupText>Tag Name</InputGroupText>
                  <Select
                    clearable={false}
                    value={currentTag.id}
                    onChange={(option) => selectTag(tags.find(x => x.id === option.value))}
                    options={tags.map(tag => ({value: tag.id, label: tag.display_name}))}
                  />
                </InputGroup><InputGroup>
                  <InputGroupText>Show</InputGroupText>
                  <Select
                    clearable={false}
                    value={query.untagged_only}
                    onChange={(option) => updateTagFilter(option.value)}
                    options={[{value: true, label: 'Untagged Records Only'}, {value: false, label: 'All Records'}]}
                  />
                </InputGroup>
              </div>
              <div className='form-inline'>
                <Button color='primary' onClick={this.props.exportReport} className='d-none d-sm-block' style={{height: '3rem'}}><FontAwesome name='download'/> Export to CSV</Button>
              </div>
            </div>
            <Table>
              <thead>
              <tr>
                <th>Dimension Value</th>
                <th>Last Seen</th>
                <th>Tag Value</th>
              </tr>
              </thead>
              <tbody>
              {page && page.map((record) =>
                <tr key={record.value}>
                  <td>{record.value}</td>
                  <td>{record.last_used}</td>
                  <td>
                    <Select
                      clearable={false}
                      isSearchable={false}
                      value={record.tag}
                      onChange={(selectedOption) => createTagOptionValue(currentTag.id, selectedOption.value, record.value)}
                      options={Object.keys(currentTag.values).map((x) => ({value: x, label: x}))}
                    />
                  </td>
                </tr>
              )}
              {tags.length > 0 && page === null && (
                <tr>
                  <td colSpan={3} style={{textAlign: 'center'}}>
                    <img src={'/spinner.gif'} alt='Spinner' />
                  </td>
                </tr>
              )}
              {page && page.length === 0 && (
                <tr>
                  <td colSpan={3} style={{textAlign: 'center'}}>
                    <div className='celebrate'>
                      <img src={'/party.png'} alt='Logo' style={{width: 64}}/>
                      Yay! All of your records have been tagged!
                    </div>
                  </td>
                </tr>
              )}
              </tbody>
            </Table>
            <TaggingPagination />
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  query: state.tagging.query,
  page: state.tagging.page,
  currentTag: state.tagging.currentTag || {},
  tags: state.config.settings.tags,
  // dimensions: state.config.dimensions.filter(x => x.id.indexOf('tag_') !== 0)
});

const mapDispatchToProps = dispatch => ({
  onInit: (clientId) => dispatch(initializeTagging(clientId)),
  selectTag: (tag) => dispatch(selectTag(tag)),
  createTagOptionValue: (tagId, option, value) => dispatch(createTagOptionValue(tagId, option, value)),
  updateTagFilter: (untaggedOnly) => dispatch(updateTagFilter(untaggedOnly)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Tagging))
