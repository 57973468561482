import React, {Component} from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";

class CreativeCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {activeIndex: 0};
  }

  // next = () => {
  //   const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
  //   this.setState({ activeIndex: nextIndex });
  // };
  //
  // previous = () => {
  //   const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
  //   this.setState({ activeIndex: nextIndex });
  // };

  render() {
    // const { activeIndex } = this.state;
    const {url} = this.props;

    // const slides = items.map((item) => {
    //   return (
    //     <CarouselItem
    //       onExiting={this.onExiting}
    //       onExited={this.onExited}
    //       key={item.src}
    //     >
    //       <video src={item.src} controls={true} style={{width: '100%'}} />
    //     </CarouselItem>
    //   );
    // });

    const isVideo = url && url.indexOf('.mp4') >= 0;

    return (
      <div>
        {isVideo ?
          <video src={url} autoPlay={true} muted={false} controls={true} style={{width: '100%'}}/>
          :
          <img src={url} style={{width: '100%'}}/>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = dispatch => ({});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CreativeCarousel))
