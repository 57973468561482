import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container} from "reactstrap";
import {initializeHome} from "../actions";
import './Dashboard.css'
import NavigationContainer from "./NavigationContainer";
import {Tabs, Tab} from "../components/Tabs";

class Dashboard2 extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    this.props.onInit(this.props.match.params.clientId);
  }

  render() {
    const {clients, stats} = this.props;

    //TODO: This is a bit ugly. Maybe clients should be an array rather than a dict
    const client_objects = Object.values(clients) //.slice(0, 3);
    client_objects.sort((a, b) => { return a.name > b.name });
    const client_ids = client_objects.map(x => x._id);

    return (
      <div>
        <NavigationContainer showDropdown={false} />
        <Container fluid={true}>
          <Tabs>
            {client_ids.map((client_id, i) => {
              const client = clients[client_id];

              return (
                <Tab key={client_id} active={client_id === 'gameofsultans'}>
                  <img alt={client.name} src={`https://nara-analytics.s3.amazonaws.com/clients/${client_id}.png`} style={{width: 36, marginRight: 10, borderRadius: 3}} />
                  {client.name}
                </Tab>
              )
            })}
          </Tabs>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  clients: state.clients.clients || {},
  stats: state.clients.stats || {}
});

const mapDispatchToProps = dispatch => ({
  onInit: (clientId) => dispatch(initializeHome(clientId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard2)
