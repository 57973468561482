import React, {Component} from 'react';
import {connect} from 'react-redux'
import './FilterButton.css'
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import {injectIntl} from 'react-intl'
import ChartSettings from "./ChartSettings";
import TooltipContent from "./TooltipContent";

class SpendChart extends Component {
  render() {
    const {spend, currency, intl, stats, metrics, chartMetrics} = this.props;
    const primaryMetricConfig = metrics.find((x) => {return x.id === chartMetrics.primary});
    const secondaryMetricConfig = metrics.find((x) => {return x.id === chartMetrics.secondary});
    const spend_mtd = stats != null ? stats.spend_mtd : null;
    const projected_spend = stats != null ? stats.projected_spend : null;
    const first_of_month = spend.find((r) => { return r.date.endsWith("-01") });
    const reference_line = first_of_month ? first_of_month.date : undefined;

    if (spend.length === 0) {
      return '';
    }

    function dateFormatter(value) {
      return value.split('-').reverse().join('/')
    }

    function primaryValueFormatter(value) {
      if (primaryMetricConfig.style === 'currency') {
        return intl.formatNumber(value, {
          style: 'currency',
          currency: primaryMetricConfig.currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        });
      } else if (primaryMetricConfig.style === 'percent') {
        return intl.formatNumber(value, {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      } else {
        return intl.formatNumber(value, {
          minimumFractionDigits: primaryMetricConfig.decimals || 0,
          maximumFractionDigits: primaryMetricConfig.decimals || 0
        });
      }
    }

    function secondaryValueFormatter(value) {
      if (secondaryMetricConfig.style === 'currency') {
        return intl.formatNumber(value, {
          style: 'currency',
          currency: secondaryMetricConfig.currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        });
      } else if (secondaryMetricConfig.style === 'percent') {
        return intl.formatNumber(value, {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      } else {
        return intl.formatNumber(value, {
          minimumFractionDigits: secondaryMetricConfig.decimals || 0,
          maximumFractionDigits: secondaryMetricConfig.decimals || 0
        });
      }
    }

    return (
      <div className='d-none d-sm-block'>
        <div style={{paddingBottom: 30}}>
          <ChartSettings />
          <ResponsiveContainer width='100%' aspect={6}>
            <AreaChart data={spend} margin={{top: 10, right: 30, left: 0, bottom: 0}}>
              <defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#EFF4FC" stopOpacity={1}/>
                  <stop offset="100%" stopColor="#EFF4FC" stopOpacity={0.2}/>
                </linearGradient>
                <linearGradient id="gradient2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#e9f6ed" stopOpacity={1}/>
                  <stop offset="100%" stopColor="#e9f6ed" stopOpacity={0.2}/>
                </linearGradient>
              </defs>
              <CartesianGrid vertical={false} stroke="#EFF1F5"/>
              <XAxis tick={{fontSize: 10}} dataKey="date" stroke='#A0A9B8' axisLine={false} tickLine={false} tickFormatter={dateFormatter} />
              <YAxis yAxisId='left' tick={{fontSize: 10}} interval={1} stroke='#A0A9B8' axisLine={false} tickLine={false} tickFormatter={primaryValueFormatter} orientation="left" />
              { secondaryMetricConfig && (
                <YAxis yAxisId='right' tick={{fontSize: 10}} interval={1} stroke='#A0A9B8' axisLine={false} tickLine={false} tickFormatter={secondaryValueFormatter} orientation="right" width={30} />
              )}
              <Tooltip content={<TooltipContent  />} />
              { primaryMetricConfig && (
                <Area type='monotone' name={primaryMetricConfig.display_name} dataKey='primary' yAxisId='left' stroke='#749DE8' fillOpacity={0.5} fill="url(#gradient)" isAnimationActive={false} animationDuration={500} />
              )}
              { secondaryMetricConfig && (
                  <Area type='monotone' name={secondaryMetricConfig.display_name} dataKey='secondary' yAxisId='right' stroke='#0fab4c' fillOpacity={0.5} fill="url(#gradient2)" isAnimationActive={false} animationDuration={500} />
              )}
              <ReferenceLine x={reference_line} yAxisId='left' stroke="#CCDDFF" strokeDasharray="3 5" />
              <Legend verticalAlign="top" height={36} />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  stats: state.spend.stats,
  spend: state.spend.daily,
  chartMetrics: state.report.definition.chart_metrics,
  metrics: state.config.metrics,
  currency: state.clients.clients[state.clients.active_client_id].currency
});

export default injectIntl(connect(mapStateToProps, null)(SpendChart));
