import React, {Component} from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Button, Card, CardBody, Table} from "reactstrap";
import './Settings.css'
import {deleteTag, updateTag, updateTagSourceDimension} from "../actions";
import AddTagButton from "./AddTagButton";
import FontAwesome from 'react-fontawesome';
import TagSettingsDialog from "./TagSettingsDialog";

class TaggingSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };
  }

  editTag = (tag) => {
    this.setState({
      tag: tag,
      modal: true
    });
  };

  save = (name, source_dimension, options) => {
    this.props.updateTag(this.state.tag.id, name, source_dimension, options);
    this.setState({
      modal: false
    });
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  render() {
    const {settings, dimensions, updateTagSourceDimension, deleteTag} = this.props;

    const confirmDelete = (tag_id) => {
      if (window.confirm('Are you sure you want to delete this tag?')) {
        deleteTag(tag_id);
      }
    };

    return (
      <div className='row'>
        <div className='col-4 info'>
          <h3>Tagging</h3>
          <p>Define custom tags for use in the Tagging page. This can be useful for things such as grouping creative concepts, grouping countries etc.</p>
          <p>The Source Dimension defines which of the available dimensions the tag will be based on. For example, you might select the 'Ad Name' dimension for creative tags.</p>
        </div>
        <div className='col-8'>
          <Card>
            <CardBody>
              <div className='top-bar'>
                <div className='section-title'>Tags</div>
                <AddTagButton />
              </div>
              <div style={{marginLeft: '-1rem', marginRight: '-1rem'}}>
              <Table>
                <thead>
                <tr>
                  <th>Tag Name</th>
                  <th>Source Dimension</th>
                  <th>Options</th>
                  <th style={{width: 1}}/>
                </tr>
                </thead>
                <tbody>
                {settings.tags.map((tag) =>
                  <tr key={tag.id}>
                    <td>{tag.display_name}</td>
                    <td>{(dimensions || []).filter(x => x.id === tag.source_dimension)[0].display_name}</td>
                    <td>
                      {Object.keys(tag.values).map((value) =>
                        <span key={value} className='tag-value'>{value}</span>
                      )}
                    </td>
                    <td style={{whiteSpace: 'nowrap'}}>
                      <Button color='secondary' size='sm' onClick={() => this.editTag(tag)} style={{height: 36, marginLeft: '0.5rem'}}><FontAwesome name='edit'/></Button>
                      <Button color='secondary' size='sm' onClick={() => confirmDelete(tag.id)} style={{height: 36, marginLeft: '0.5rem'}}><FontAwesome name='trash'/></Button>
                    </td>
                  </tr>
                )}
                {settings.tags.length === 0 && (
                  <tr>
                    <td colSpan={4} style={{color: '#888888'}}>Click 'Create Tag' to add your first tag.</td>
                  </tr>
                )}
                </tbody>
              </Table>
              </div>
            </CardBody>
          </Card>
        </div>
        <TagSettingsDialog tag={this.state.tag} dimensions={dimensions} isOpen={this.state.modal} toggle={this.toggle} onSave={this.save} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.config.settings,
  dimensions: state.config.dimensions.filter(x => x.id.indexOf('tag_') !== 0)
});

const mapDispatchToProps = dispatch => ({
  updateTagSourceDimension: (tag_id, source_dimension) => dispatch(updateTagSourceDimension(tag_id, source_dimension)),
  deleteTag: (tag_id) => dispatch(deleteTag(tag_id)),
  updateTag: (tag_id, name, source_dimension, options) => dispatch(updateTag(tag_id, name, source_dimension, options))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TaggingSettings))
