import React from 'react';
import {FormattedNumber} from "react-intl";

const DataTableMetricCell = ({column, value}) => (
  <FormattedNumber
    style={column.style || undefined}
    currency={column.currency || undefined}
    minimumFractionDigits={column.decimals !== undefined ? column.decimals : undefined}
    maximumFractionDigits={column.decimals !== undefined ? column.decimals : undefined}
    value={value || 0}
  />
);

export default DataTableMetricCell;