import React, {Component, Fragment} from 'react';
import {withRouter} from "react-router-dom";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavLink,
  UncontrolledDropdown
} from "reactstrap";
import './Navigation.css'
import {connect} from "react-redux";
import UserIcon from "./UserIcon";
import {changeClient, loadUser} from "../actions";

const LogoutLink = withRouter(({ logout }) => <DropdownItem onClick={() => logout()}>Logout</DropdownItem>);

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  componentDidMount() {
    this.props.loadUserData();
  }

  render() {
    const {logout, clients, activeClientId, onSelectClient, user, feature_switches, showDropdown, recommendationCount} = this.props;

    return (
      <Navbar expand="md">
        <NavbarBrand tag='div' className={showDropdown ? '' : 'hide-border'}>
          <a href='/' style={{display: 'flex', padding: 0, margin: 0}}><img src={'/logo.png'} style={{height: 32}} alt='Logo'/></a>
          {showDropdown &&
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              {(clients[activeClientId] || {}).name}
            </DropdownToggle>
            <DropdownMenu>
              {(Object.values(clients) || []).map((client) => (
                <DropdownItem key={client._id} onClick={() => onSelectClient(client._id)}>{client.name}</DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
          }
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle}/>

        <Collapse isOpen={this.state.isOpen} navbar>
          {showDropdown &&
            <NavLink href={`/${activeClientId}/`} className={window.location.pathname.indexOf('/targets') < 0 && window.location.pathname.indexOf('/settings') < 0 && window.location.pathname.indexOf('/tagging') < 0 && window.location.pathname.indexOf('/recommendations') < 0 ? 'active' : ''}>Dashboard</NavLink>
          }
          {showDropdown && feature_switches.indexOf('recommendations') >= 0 &&
          <NavLink href={`/${activeClientId}/recommendations`} className={window.location.pathname.indexOf('/recommendations') >= 0 ? 'active' : ''}>Recommendations{recommendationCount > 0 && <span className='count'>{recommendationCount}</span>}</NavLink>
          }
          {showDropdown &&
            <NavLink href={`/${activeClientId}/targets`} className={window.location.pathname.indexOf('/targets') >= 0 ? 'active' : ''}>Targets</NavLink>
          }
          {showDropdown &&
            <NavLink href={`/${activeClientId}/tagging`} className={window.location.pathname.indexOf('/tagging') >= 0 ? 'active' : ''}>Tagging</NavLink>
          }
          {showDropdown &&
            <NavLink href={`/${activeClientId}/settings`} className={window.location.pathname.indexOf('/settings') >= 0 ? 'active' : ''}>Settings</NavLink>
          }
          <Nav className="ms-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <UserIcon user={user} />
              </DropdownToggle>
              <DropdownMenu end={true}>
                { user && user.admin &&
                  <Fragment>
                    <DropdownItem onClick={() => this.props.history.push(`/settings/users`)}>User Management</DropdownItem>
                    <DropdownItem divider />
                  </Fragment>
                }
                <LogoutLink logout={logout}/>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}

const mapStateToProps = state => ({
  clients: state.clients.clients,
  recommendationCount: state.recommendations.length,
  activeClientId: state.clients.active_client_id,
  user: state.user.data,
  feature_switches: (state.user.data || {}).feature_switches || []
});

const mapDispatchToProps = dispatch => ({
  onSelectClient: (client) => dispatch(changeClient(client)),
  loadUserData: () => dispatch(loadUser())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation));
