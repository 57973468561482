import React, {Component} from 'react';
import {DateRangePicker} from "react-bootstrap-daterangepicker";
import {InputGroup, InputGroupText} from "reactstrap";
import moment from "moment";
import './DateRange.css'

class DateRange extends Component {
  handleApply = (event, picker) => {
    this.props.onApply(picker.startDate.format('YYYY-MM-DD'), picker.endDate.format('YYYY-MM-DD'))
  };

  render() {
    return (
      <InputGroup style={{height: '3rem'}}>
        <InputGroupText>Date Range</InputGroupText>
        <DateRangePicker
          initialSettings={{
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            locale: {
              format: 'DD/MM/YYYY'
            },
            ranges: {
              'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
              'Last 28 Days': [moment().subtract(28, 'days'), moment().subtract(1, 'days')],
              'Last 30 Days': [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
              'This Year': [moment().startOf('year'), moment().subtract(1, 'days')]
            },
            alwaysShowCalendars: true,
            showCustomRangeLabel: false,
            maxDate: moment()
          }}
          onApply={this.handleApply}
        >
          <input type="text" className="form-control col-4"/>
        </DateRangePicker>
      </InputGroup>
    )
  }
}

export default DateRange;
