import React from 'react';
import './App.css'
import {Route} from "react-router-dom";
import Login from "./Login";
import {Switch} from "react-router";
import Dashboard from "./Dashboard";
import Home from "./Home";
import {PrivacyPolicy} from "../components/PrivacyPolicy";
import {TermsOfService} from "../components/TermsOfService";
import Targets from "./Targets";
import Settings from "./Settings";
import Tagging from "./Tagging";
import Recommendations from "./Recommendations";
import Dashboard2 from "./Dashboard2";
import Users from "./Users";

const App = () => (
  <div>
    <Switch>
      <Route exact path="/" component={Home}/>
      <Route path="/dashboard" component={Dashboard2}/>
      <Route path="/login" component={Login}/>
      <Route path="/privacy" component={PrivacyPolicy}/>
      <Route path="/terms" component={TermsOfService}/>
      <Route path="/settings/users" component={Users}/>
      <Route path="/:clientId/targets" component={Targets}/>
      <Route path="/:clientId/recommendations" component={Recommendations}/>
      <Route path="/:clientId/settings" component={Settings}/>
      <Route path="/:clientId/tagging" component={Tagging}/>
      <Route path="/:clientId/:reportId" render={(props) => <Dashboard {...props}/>}/>
      <Route path="/:clientId" render={(props) => <Dashboard {...props}/>}/>
    </Switch>
  </div>
);

export default App
