import React, {Component} from 'react';
import {connect} from 'react-redux'
import './Stats.css'
import {FormattedNumber, injectIntl} from 'react-intl'
import {Card, CardBody, Container} from "reactstrap";
import DataTableMetricCell from "../components/DataTableMetricCell";

class Stats extends Component {
  render() {
    const {spend, currency, intl, stats, metrics, chartMetrics, kpi} = this.props;
    const primaryMetricConfig = metrics.find((x) => {return x.id === chartMetrics.primary});
    const secondaryMetricConfig = metrics.find((x) => {return x.id === chartMetrics.secondary});
    const spend_mtd = stats != null ? stats.spend_mtd : null;
    const projected_spend = stats != null ? stats.projected_spend : null;
    const first_of_month = spend.find((r) => { return r.date.endsWith("-01") });
    const reference_line = first_of_month ? first_of_month.date : undefined;


    function dateFormatter(value) {
      return value.split('-')[2]
    }

    function primaryValueFormatter(value) {
      if (primaryMetricConfig.style === 'currency') {
        return intl.formatNumber(value, {
          style: 'currency',
          currency: primaryMetricConfig.currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        });
      } else if (primaryMetricConfig.style === 'percent') {
        return intl.formatNumber(value, {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      } else {
        return intl.formatNumber(value, {
          minimumFractionDigits: primaryMetricConfig.decimals || 0,
          maximumFractionDigits: primaryMetricConfig.decimals || 0
        });
      }
    }

    function secondaryValueFormatter(value) {
      if (secondaryMetricConfig.style === 'currency') {
        return intl.formatNumber(value, {
          style: 'currency',
          currency: secondaryMetricConfig.currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        });
      } else if (secondaryMetricConfig.style === 'percent') {
        return intl.formatNumber(value, {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      } else {
        return intl.formatNumber(value, {
          minimumFractionDigits: secondaryMetricConfig.decimals || 0,
          maximumFractionDigits: secondaryMetricConfig.decimals || 0
        });
      }
    }

    return (
      <div className='stats'>

        {spend_mtd !== null && (
        <div className='stat'>
          <p>Spend (MTD)</p>
            <FormattedNumber
              style='currency'
              currency={currency}
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              value={spend_mtd}
            />
        </div>
        )}

        {projected_spend !== null && (
        <div className='stat'>
          <p>Projected Spend</p>

            <FormattedNumber
              style='currency'
              currency={currency}
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              value={projected_spend}
            />
        </div>
        )}

        {kpi && (
        <div className='stat'>
          <p>{kpi.short_name}</p>
          <DataTableMetricCell column={kpi} value={kpi.value} />
        </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  stats: state.spend.stats,
  kpi: state.spend.kpi,
  spend: state.spend.daily,
  chartMetrics: state.report.definition.chart_metrics,
  metrics: state.config.metrics,
  currency: state.clients.clients[state.clients.active_client_id].currency
});

export default injectIntl(connect(mapStateToProps, null)(Stats));
