import React, {Component} from 'react';
import {connect} from "react-redux";
import ReportTable from "./ReportTable";
import {Button, Container, InputGroup, InputGroupText} from "reactstrap";
import {
  exportReport,
  initializeDashboard,
  runReport,
  selectReport,
  updateDateRange,
  updateGroupCreatives
} from "../actions";
import ReportPagination from "./ReportPagination";
import './Dashboard.css'
import FontAwesome from 'react-fontawesome';
import Select from "react-select";
import FilterButton from "./FilterButton";
import EditColumnsButton from "./EditColumnsButton";
import SaveReportButton from "./SaveReportButton";
import {push} from 'connected-react-router';
import SpendChart from "./SpendChart";
import NavigationContainer from "./NavigationContainer";
import queryString from 'query-string';
import DateRange from "../components/DateRange";
import moment from "moment";
import Stats from "./Stats";
import Switch from "react-switch";

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      dropdownOpen: false
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const reportId = nextProps.match.params.reportId;

    if (nextProps.location !== this.props.location) {
      console.log('Location changed');

      if (reportId !== undefined) {
        console.log('Report id is defined, selecting report');
        this.props.selectReport(reportId);
      } else {
        console.log('Report id not defined');
        this.props.onInit(nextProps.match.params.clientId)
      }
    }
  }

  componentDidMount() {
    // Parse query string an ensure data types are correct
    const query = queryString.parse(this.props.location.search);
    if (query.page_size !== undefined) {
      query.page_size = parseInt(query.page_size);
    }
    if (query.page_index !== undefined) {
      query.page_index = parseInt(query.page_index);
    }

    console.log('Initializing dashboard');
    this.props.onInit(this.props.match.params.clientId, this.props.match.params.reportId, query);
  }

  selectReport = (report) => {
    this.props.history.push(`/${this.props.match.params.clientId}/${report.value}`);
  };

  render() {
    const options = this.props.savedReports.map(report => ({value: report._id, label: this.props.report.definition.parent_report_id === report._id ? report.name + ' *' : report.name}));
    const reportId = this.props.report.definition.parent_report_id || this.props.match.params.reportId;
    const selectedOption = options.find(x => x['value'] === reportId);

    return (
      <div>
        {this.props.isReady ? (
          <div className='d-flex flex-column'>
            <NavigationContainer showDropdown={true}/>
            <div id='dashboard'>
              <div className='d-flex justify-content-between dashboard-options'>
                <div className='form-inline'>
                  <InputGroup>
                    <InputGroupText>Report</InputGroupText>
                    <Select
                      className='Select'
                      clearable={false}
                      name="form-field-name"
                      value={selectedOption}
                      onChange={this.selectReport}
                      options={options}
                    />
                  </InputGroup>
                  <DateRange
                    startDate={moment(this.props.report.query.start_date)}
                    endDate={moment(this.props.report.query.end_date)}
                    onApply={this.props.updateDateRange}
                  />
                  <FilterButton />
                  <EditColumnsButton />
                  <SaveReportButton />
                </div>
                <div className='form-inline'>
                <Button color='primary' onClick={this.props.exportReport} className='d-none d-sm-block' style={{height: '3rem'}}><FontAwesome name='download'/> Export to CSV</Button>
                </div>
              </div>
              <Stats />
              <SpendChart />
              <div style={{marginTop: 10}}>
                <ReportTable report={this.props.report}/>
              </div>
              <ReportPagination/>
            </div>
          </div>
        ) : (<div id='loading'>Loading...</div>)}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  clients: state.clients,
  report: state.report,
  isReady: state.clients.active_client_id !== undefined,
  savedReports: state.config.saved_reports,
});

const mapDispatchToProps = dispatch => ({
  onInit: (clientId, reportId, query) => dispatch(initializeDashboard(clientId, reportId, query)),
  exportReport: () => dispatch(exportReport()),
  onSelectClient: (client) => dispatch(push('/' + client + '/')),
  selectReport: (reportId) => dispatch(selectReport(reportId)),
  runReport: (query) => dispatch(runReport(query)),
  updateDateRange: (start, end) => dispatch(updateDateRange(start, end)),
  toggleGroupCreatives: (toggleOn) => dispatch(updateGroupCreatives(toggleOn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
