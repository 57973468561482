import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import {updateColumns} from "../actions";
import './FilterButton.css'
import ColumnSelector from "./ColumnSelector";

class EditColumnsButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      dimensions: [],
      metrics: []
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      dimensions: props.dimensions,
      metrics: props.metrics
    })
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = (dimensions, metrics) => {
    this.setState({
      dimensions: dimensions,
      metrics: metrics
    })
  };

  apply = () => {
    this.props.onApply(this.state.dimensions, this.state.metrics);
    this.setState({
      modal: false
    });
  };

  render() {
    return (
      <div className='d-none d-sm-block'>
        <div id="draggable" style={{position: 'absolute', pointerEvents: 'none'}}></div>
        <Button id="Popover2" color='secondary' onClick={this.toggle}>
          <FontAwesome name='columns' style={{marginRight: 5}}/> Edit Columns
        </Button>
        <Modal size='lg' centered={true} isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Choose which columns you see</ModalHeader>
          <ModalBody>
            <ColumnSelector selectedColumns={this.state.dimensions} onUpdate={(dimensions) => this.setState({...this.state, dimensions})} onChange={this.onChange}/>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.apply}>Save</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  onApply: (dimensions, metrics) => dispatch(updateColumns(dimensions, metrics)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditColumnsButton);
