import React, {Component} from 'react';
import {Button} from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import {connect} from "react-redux";
import {createTag} from "../actions";
import TagSettingsDialog from "./TagSettingsDialog";

class AddTagButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  save = (name, source_dimension, options) => {
    this.props.createTag(name, source_dimension, options);
    this.setState({
      modal: false
    });
  };

  render() {
    const {config, createTag} = this.props;

    return (
      <div>
        <Button id="Popover4" style={{height: '3rem'}} color='primary' onClick={this.toggle}>
          <FontAwesome name='plus' style={{marginRight: 5}}/>Create Tag
        </Button>
        <TagSettingsDialog dimensions={config.dimensions} isOpen={this.state.modal} toggle={this.toggle} onSave={this.save} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  config: state.config
});

const mapDispatchToProps = dispatch => ({
  createTag: (name, source_dimension, options) => dispatch(createTag(name, source_dimension, options))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTagButton);
