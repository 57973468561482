const tagging = (state = {currentTag: undefined, page: null, row_count: 0, query: {page_index: 0, page_size: 10, untagged_only: true}}, action) => {
  switch (action.type) {
    case 'SELECT_TAG':
      return {
        ...state,
        currentTag: action.tag
      };
    case 'RECEIVED_TAG_REPORT_PAGE':
      return {
        ...state,
        page: action.data.data,
        row_count: action.data.row_count
      };
    case 'UPDATE_TAGGING_PAGE_INDEX':
      return {
        ...state,
        page: null,
        query: {
          ...state.query,
          page_index: action.index
        }
      };
    case 'UPDATE_TAG_FILTER':
      return {
        ...state,
        query: {
          ...state.query,
          untagged_only: action.untaggedOnly
        }
      };
    case 'UPDATE_TAGGING_PAGE_SIZE':
      return {
        ...state,
        page: null,
        query: {
          ...state.query,
          page_index: 0,
          page_size: action.value
        }
      };
    case 'UPDATE_TAG_OPTION_VALUE':
      return {
        ...state,
        page: state.page.map((item) => {
          if (item.value !== action.value) {
            return item
          }

          return {
            ...item,
            tag: action.tag
          }
        })
      };
    default:
      return state;
  }
};

export default tagging;
