import {combineReducers} from 'redux'
import report from "./report";
import user from "./user";
import clients from "./clients";
import config from "./config";
import spend from "./spend";
import targets from "./targets";
import history from '../history';
import {connectRouter} from 'connected-react-router'
import tagging from "./tagging";
import {reducer as toastrReducer} from 'react-redux-toastr'
import recommendations from "./recommendations";
import users from "./users";

export default (history) => combineReducers({
  router: connectRouter(history),
  toastr: toastrReducer,
  report,
  clients,
  config,
  user,
  spend,
  targets,
  tagging,
  recommendations,
  users
})