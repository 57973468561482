import moment from "moment";

const initialState = {dimensions: [], metrics: [], targets: [], values: [], unmatched_records: [], start_date: moment().subtract(1, 'days'), end_date: moment().subtract(1, 'days')};

const targets = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_TARGETS':
      return action.targets;
    default:
      return state;
  }
};

export default targets;
