import moment from "moment";

const DEFAULT_CONFIG = {
  definition: {
    dimensions: [],
    metrics: [],
    sort_order: [],
    filters: [],
    group_creatives: false,
    chart_metrics: {
      primary: 'spend',
      secondary: null
    }
  },
  query: {
    start_date: moment().subtract(31, 'days').format('YYYY-MM-DD'),
    end_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    page_index: 0,
    page_size: 10,
  },
  dimensions: [],
  metrics: [],
  data: [],
  totals: []
};

const query = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_REPORT_QUERY':
      return {
        ...DEFAULT_CONFIG.query,
        ...action.query
      };
    case 'UPDATE_DATE_RANGE':
      return {
        ...state,
        start_date: action.startDate,
        end_date: action.endDate
      };
    case 'UPDATE_PAGE_SIZE':
      return {
        ...state,
        page_size: action.value,
        page_index: 0
      };
    case 'UPDATE_REPORT_PAGE_INDEX':
      return {
        ...state,
        page_index: action.index
      };
    case 'SELECT_CLIENT': // Reset pagination but not start/end dates when selecting a new client
    case 'RESET_PAGINATION':
      return {
        ...state,
        page_index: 0,
        row_count: 0
      };
    default:
      return state;
  }
};

const definition = (state = {}, action) => {
  switch (action.type) {
    case 'SELECT_REPORT':
      return {
        ...DEFAULT_CONFIG.definition,
        ...action
      };
    default:
      return state;
  }
};

const report = (state = DEFAULT_CONFIG, action) => {
  switch (action.type) {
    case 'REQUEST_REPORT_DATA':
      return {
        ...state,
        loading: true
      };
    case 'RECEIVE_REPORT_DATA':
      return {
        ...state,
        data: action.data.data,
        dimensions: action.data.dimensions,
        metrics: action.data.metrics,
        totals: action.data.totals,
        row_count: action.data.row_count,
        loading: false
      };
    // case 'SORT_REPORT':
    case 'UPDATE_REPORT_PAGE_INDEX':
    case 'RESET_PAGINATION':
    case 'UPDATE_PAGE_SIZE':
    case 'UPDATE_DATE_RANGE':
    case 'UPDATE_REPORT_QUERY':
      return {
        ...state,
        query: query(state.query, action)
      };
    case 'SELECT_REPORT':
      return {
        ...state,
        definition: definition(state.definition, action)
      };
    case 'SELECT_CLIENT':
      return {
        ...DEFAULT_CONFIG,
        query: query(state.query, action)
      };
    default:
      return state;
  }
};

export default report;
