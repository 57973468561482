import React, {Component} from 'react';
import PaginationControls from "../components/PaginationControls";
import {selectPage, selectTaggingPage, updatePageSize, updateTaggingPageSize} from "../actions";
import {connect} from "react-redux";

class TaggingPagination extends Component {
  render() {
    const {rowCount, query, pageSize, onPageSelect, onPageSizeChange} = this.props;

    return (
      <PaginationControls
        rowCount={rowCount}
        pageSize={query.page_size}
        pageIndex={query.page_index}
        onPageSelect={onPageSelect}
        onPageSizeChange={onPageSizeChange}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  rowCount: state.tagging.row_count,
  query: state.tagging.query,
});

const mapDispatchToProps = dispatch => ({
  onPageSelect: (index) => dispatch(selectTaggingPage(index)),
  onPageSizeChange: (selectedOption) => dispatch(updateTaggingPageSize(selectedOption.value))
});

export default connect(mapStateToProps, mapDispatchToProps)(TaggingPagination);
