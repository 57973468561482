import React, {Component} from 'react';
import {connect} from 'react-redux'
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Popover,
  PopoverBody
} from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import {deleteReport, saveReport, saveReportAs} from "../actions";
import './FilterButton.css'
import history from '../history';
import queryString from "query-string";

class SavedReportButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popoverOpen: false,
      dropdownOpen: false,
      dimensions: props.dimensions,
      metrics: props.metrics
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      dimensions: props.dimensions,
      metrics: props.metrics
    })
  }

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  saveReport = () => {
    if (window.confirm('Are you sure you want to overwrite this report?')) {
      this.props.saveReport();
    }
  };

  deleteReport = () => {
    if (window.confirm('Are you sure you want to delete this report?')) {
      this.props.deleteReport();
    }
  };

  openSaveAsDialog = () => {
    this.setState({
      popoverOpen: true
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      popoverOpen: false
    });

    this.props.saveReportAs(this.reportName.value);
  };

  revertReport = () => {
    history.push({
      pathname: `/${this.props.clientId}/${this.props.parentReportId}`,
      search: queryString.stringify(this.props.query)
    });
  };

  render() {
    return (
      <div className='d-none d-sm-block'>
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
          <Button id="Popover3" color="secondary" onClick={this.saveReport}>
            <FontAwesome name='save' style={{marginRight: 5}}/> Save
          </Button>
          <DropdownToggle caret color="secondary" />
          <DropdownMenu>
            <DropdownItem onClick={this.openSaveAsDialog}>Save as...</DropdownItem>
            <DropdownItem onClick={this.revertReport} disabled={this.props.parentReportId === undefined}>Revert</DropdownItem>
            <DropdownItem onClick={this.deleteReport}>Delete</DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
        <Popover placement="bottom" isOpen={this.state.popoverOpen} target="Popover3" toggle={this.toggle}>
          <PopoverBody>
            <div style={{paddingTop: 5, paddingBottom: 5}}>
              <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Label for="email">Report Name</Label>
                  <Input type="text" innerRef={(input) => (this.reportName = input)} />
                </FormGroup>
                <div style={{marginTop: '10px', textAlign: 'right'}}>
                  <Button color='primary'>Apply</Button>
                </div>
              </Form>
            </div>
          </PopoverBody>
        </Popover>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  clientId: state.clients.active_client_id,
  config: state.config,
  dimensions: state.report.definition.dimensions,
  metrics: state.report.definition.metrics,
  parentReportId: state.report.definition.parent_report_id,
  query: state.report.query
});

const mapDispatchToProps = dispatch => ({
  saveReport: () => dispatch(saveReport()),
  saveReportAs: (name) => dispatch(saveReportAs(name)),
  deleteReport: () => dispatch(deleteReport())
});

export default connect(mapStateToProps, mapDispatchToProps)(SavedReportButton);
