const recommendations = (state = [], action) => {
  switch (action.type) {
    case 'UPDATE_RECOMMENDATIONS':
      return action.recommendations;
    case 'SELECT_CLIENT':
      return [];
    default:
      return state;
  }
};

export default recommendations;
