const initialState = {clients: {}};

const client = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_KPI_VALUE':
      return {
        ...state,
        [action.client_id]: {
          ...state[action.client_id],
          kpi_value: action.value
        }
      };
    default:
      return state;
  }
};

const clients = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIALIZE_CLIENTS':
      return {
        ...state,
        clients: action.clients,
        active_client_id: state.active_client_id || Object.keys(action.clients)[0]
      };
    case 'SELECT_CLIENT':
      return {
        ...state,
        active_client_id: action.clientId || Object.keys(state.clients)[0]
      };
    case 'RECEIVE_CLIENT_STATS':
      return {
        ...state,
        stats: {
          ...state.stats,
          [action.stats.client_id]: action.stats
        }
      };
    case 'UPDATE_KPI_VALUE':
      return {
        ...state,
        clients: client(state.clients, action)
      };
    default:
      return state;
  }
};

export default clients;
