import React, {Component} from 'react';
import './DrillDown.css'
import FontAwesome from 'react-fontawesome';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import {drillDown} from "../actions";
import {connect} from "react-redux";


class DrillDown extends Component {
  constructor(props) {
    super();

    this.state = {
      // dimensionId: props.dimensionId,
      // dimensionValue: props.dimensionValue,
      popoverOpen: false
    }
  }

  menuItemClicked = (dimension_id) => {
    console.log('Click!');
    console.log(this.state);
    this.setState({
      popoverOpen: false
    });
    this.props.drillDown(this.props.dimensionId, this.props.dimensionValue, dimension_id)
  };

  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  };

  render() {
    const {dimensions, metrics, chartMetrics} = this.props;

    return (
        <Dropdown className='drilldown' isOpen={this.state.popoverOpen} toggle={this.toggle}>
          <DropdownToggle color="link"><FontAwesome name='chevron-down'/></DropdownToggle>
          <DropdownMenu>
            {dimensions.map((dimension) => (
              <DropdownItem key={dimension.id} onClick={() => this.menuItemClicked(dimension.id)}>{dimension.display_name}</DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
    );
  }
}

const mapStateToProps = state => ({
  // chartMetrics: state.report.definition.chart_metrics,
  dimensions: state.config.dimensions
});

const mapDispatchToProps = dispatch => ({
  drillDown: (dimensionId, dimensionValue, subDimensionId) => dispatch(drillDown(dimensionId, dimensionValue, subDimensionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrillDown);