import React, {Component} from 'react';
import {Button, ButtonGroup} from "reactstrap";
import FontAwesome from 'react-fontawesome';
import './PaginationControls.css'
import Select from "react-select";

class PaginationControls extends Component {
  render() {
    const {rowCount, pageSize, pageIndex, onPageSelect, onPageSizeChange} = this.props;
    const pageCount = (Math.ceil(rowCount / pageSize)) || 1;

    return (
      <div className='table-pagination'>
        <div style={{marginRight: 10}}>Show rows</div>
        <Select
          clearable={false}
          name="form-field-name"
          value={{ value: pageSize, label: pageSize }}
          onChange={onPageSizeChange}
          options={[
            { value: 10, label: '10' },
            { value: 20, label: '20' },
            { value: 50, label: '50' },
            { value: 100, label: '100' },
            { value: 200, label: '200' },
          ]}
        />
        <div style={{marginRight: 10, marginLeft: 10}}>Page {pageIndex + 1} of {pageCount}</div>
        <ButtonGroup>
          <Button color='secondary' disabled={pageIndex === 0} onClick={() => onPageSelect(Math.max(pageIndex - 1, 0))}><FontAwesome name='chevron-left' /></Button>
          <Button color='secondary' disabled={pageIndex === pageCount - 1} onClick={() => onPageSelect(Math.min(pageIndex + 1, pageCount - 1))}><FontAwesome name='chevron-right' /></Button>
        </ButtonGroup>
      </div>
    );
  }
}

export default PaginationControls
