const initialState = {stats: null, daily: [], history: {}};

const history = (state = initialState, action) => {
  switch (action.type) {
    case 'RECEIVE_CHANGE_HISTORY':
      return {
        ...state,
        [action.date]: action.value
      };
    case 'RESET_CHANGE_HISTORY':
      return {};
    default:
      return state;
  }
};

const spend = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_CHANGE_HISTORY':
    case 'RECEIVE_CHANGE_HISTORY':
      return {
        ...state,
        history: history(state.history, action)
      };
    case 'RECEIVE_SPEND_DATA':
      return {
        ...state,
        daily: action.daily,
        stats: action.stats,
        kpi: action.kpi
      };
    case 'SELECT_CLIENT':
      return initialState;
    default:
      return state;
  }
};

export default spend;
