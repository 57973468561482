import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button} from "reactstrap";
import './ReportFilters.css'
import Select from "react-select";
import FontAwesome from 'react-fontawesome';
import ReactTags from 'react-tag-autocomplete';

class ReportFilters extends Component {
  constructor(props) {
    super(props);

    let filters = props.filters;

    if (filters.length === 0) {
      filters = [{field: null, operator: 'EQUALS', values: []}];
    }

    // Temporary hack for old-style single-value filters
    for (const filter of filters) {
      if (filter.value && !filter.values) {
        filter.values = [filter.value];
      }
    }

    this.state = {
      filters: filters
    }
  }

  updateFilter = (index, field, operator, values) => {
    let newArray = this.state.filters.slice();
    newArray[index] = {field: field, operator: operator, values: values};

    this.setState({
      filters: newArray
    }, () => this.props.onChange(this.state.filters))
  };

  addFilter = () => {
    this.setState({
      filters: [...this.state.filters, {field: null, operator: 'EQUALS', values: []}]
    }, () => this.props.onChange(this.state.filters))
  };

  removeFilter = (index) => {
    let filters = [
      ...this.state.filters.slice(0, index),
      ...this.state.filters.slice(index + 1)
    ];

    if (filters.length === 0) {
      filters = [{field: null, operator: 'EQUALS', values: []}];
    }

    this.setState({
      filters: filters
    }, () => this.props.onChange(this.state.filters))
  };

  render() {
    const {dimensions} = this.props;

    return (
      <div className='filters'>
        {this.state.filters.map((filter, i) => {
          const dimensionOptions = dimensions.map((x) => ({value: x.id, label: x.display_name}));
          const selectedDimension = dimensionOptions.find(x => x.value === filter.field)

          const operatorOptions = [
            {value: 'EQUALS', label: 'Equals'},
            {value: 'CONTAINS', label: 'Contains'},
            {value: 'NOT_CONTAINS', label: 'Does Not Contain'},
            {value: 'STARTS_WITH', label: 'Starts With'},
          ];
          const selectedOperator = operatorOptions.find(x => x.value === filter.operator)

          return (
            <div key={i} className='filter'>
              <Select
                className='Select'
                clearable={false}
                name="field"
                value={selectedDimension}
                onChange={(selectedOption) => this.updateFilter(i, selectedOption.value, filter.operator, filter.values)}
                options={dimensionOptions}
              />
              <Select
                className='Select'
                clearable={false}
                name="field"
                value={selectedOperator}
                onChange={(selectedOption) => this.updateFilter(i, filter.field, selectedOption.value, filter.values)}
                options={operatorOptions}
              />
              <ReactTags
                tags={filter.values.map((x) => {
                  return {name: x}
                })}
                placeholder='Enter value'
                minQueryLength={1}
                allowNew={true}
                addOnBlur={true}
                onDelete={(index) => this.updateFilter(i, filter.field, filter.operator, filter.values.filter((x, idx) => {
                  return idx !== index
                }))}
                onAddition={(tag) => this.updateFilter(i, filter.field, filter.operator, filter.values.concat([tag.name]))}/>
              <Button color='danger' size='sm' onClick={() => this.removeFilter(i)}
                      style={{height: 39, marginLeft: 5}}><FontAwesome name='trash'/></Button>
            </div>
          );
        })}
        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 10, marginBottom: 3}}>
          <Button color='link' onClick={this.addFilter}>+ Add Filter</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dimensions: state.config.dimensions,
  filters: state.report.definition.filters || []
});

export default connect(mapStateToProps, null)(ReportFilters);
