import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import {updateFilters} from "../actions";
import ReportFilters from "./ReportFilters";
import './FilterButton.css'

class FilterButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      filters: props.filters
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  apply = () => {
    this.setState({
      filters: this.state.filters.filter((x) => {return x.values.length > 0})
    }, () => {
      this.props.onChange(this.state.filters);
      this.setState({
        modal: false
      });
    });
  };

  getButtonLabel = (filters) => {
    switch (filters.length) {
      case 0:
        return 'Add filter';
      case 1:
        return '1 filter';
      default:
        return filters.length + ' filters';
    }
  };

  render() {
    const {definition, onChange} = this.props;

    return (
      <div>
        <Button id="Popover1" color='secondary' onClick={this.toggle}>
          <FontAwesome name='filter' style={{marginRight: 5}}/>{ this.getButtonLabel(definition.filters) }
        </Button>
        <Modal size='lg' centered={true} isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Query builder</ModalHeader>
          <ModalBody>
            <ReportFilters onChange={(filters) => this.setState({filters: filters})} />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.apply}>Apply</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  config: state.config,
  definition: state.report.definition
});

const mapDispatchToProps = dispatch => ({
  onChange: (filters) => dispatch(updateFilters(filters))
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterButton);
