import React from 'react';
import FontAwesome from 'react-fontawesome';

const DataTableHeaderCell = ({displayName, sortIndex, sortDirection, onClick}) => {
  let sortIcon = <span className='sort-icon'><FontAwesome name='sort'/></span>;

  if (sortIndex >= 0) {
    if (sortDirection === 'ASC') {
      sortIcon = <span className='sort-icon'>{sortIndex + 1} <FontAwesome name='sort-up'/></span>;
    } else {
      sortIcon = <span className='sort-icon'>{sortIndex + 1} <FontAwesome name='sort-down'/></span>;
    }
  }

  return (
    <div className='header' onClick={onClick}>
      {displayName}
      {sortIcon}
    </div>
  );
};

export default DataTableHeaderCell;